import { useCallback, useRef } from "react";

const useNavbarRefs = () => {
	const _navbar = useRef();
	const navbarRef = useCallback(el => {
		_navbar.current = el;
	}, []);
	const _navArea = useRef();
	const navAreaRef = useCallback(el => {
		_navArea.current = el;
	}, []);
	const _navToggle = useRef();
	const navToggleRef = useCallback(el => {
		_navToggle.current = el;
	}, []);
	return { _navToggle, _navbar, navbarRef, navAreaRef, navToggleRef };
};

export default useNavbarRefs;
