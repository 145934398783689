import React, { useCallback } from "react";
import useSheet from "react-jss";
import cx from "classnames";
import MenuLink from "./menu-link";

const MenuItems = ({ tabItems, classes, search, user, setOpenMenu }) => {
	const hideMenu = useCallback(() => {
		setOpenMenu(null);
	}, [setOpenMenu]);

	if (!tabItems) return null;
	const [bidding, purchases, account] = tabItems;
	const nonUserTopItemCx = cx({
		[classes.topItem]: true,
		"col-md-4": user,
		"col-md-12": !user
	});

	return tabItems.length > 1 ? (
		<div>
			{user ? (
				<>
					<div className={`col-md-4 ${classes.topItem}`} key={bidding.text}>
						<header className={classes.header}>{bidding.text}</header>
						<ul>
							{bidding.links.map(innerLink => (
								<li key={innerLink.text}>
									<MenuLink
										hideMenu={hideMenu}
										key={innerLink.text}
										href={innerLink.href}
									>
										{innerLink.text}
									</MenuLink>
								</li>
							))}
						</ul>
					</div>
					<div className={`col-md-4 ${classes.topItem}`} key={purchases.text}>
						<header className={classes.header}>{purchases.text}</header>
						<ul>
							{purchases.links.map(innerLink => (
								<li key={innerLink.text}>
									<MenuLink
										hideMenu={hideMenu}
										key={innerLink.text}
										href={innerLink.href}
									>
										{innerLink.text}
									</MenuLink>
								</li>
							))}
						</ul>
					</div>
				</>
			) : null}
			<div className={nonUserTopItemCx} key={account.text}>
				<header className={classes.header}>{account.text}</header>
				<ul>
					{account.links.map(innerLink => (
						<li key={innerLink.text}>
							<MenuLink
								hideMenu={hideMenu}
								key={innerLink.text}
								href={innerLink.href}
							>
								{innerLink.text}
							</MenuLink>
						</li>
					))}
				</ul>
			</div>
		</div>
	) : (
		<div>
			<li key={tabItems[0].text} className={classes.singleItem}>
				{search ? (
					<div className={classes.search}>{tabItems[0].text}</div>
				) : (
					<MenuLink
						hideMenu={hideMenu}
						className={classes.menuItem}
						href={tabItems[0].href}
						style={{ paddingBottom: "10px" }}
						onClick={hideMenu}
					>
						{tabItems[0].text}
					</MenuLink>
				)}
			</li>
		</div>
	);
};

const styles = {
	header: {
		color: "#6eb5e5",
		padding: [16, 12, 16, 40]
	},
	search: {
		padding: [10, 0, 10, 10]
	},
	topItem: {
		position: "relative"
	},
	singleItem: {
		padding: [8, 0, 16, 0]
	}
};

export default useSheet(styles)(MenuItems);
