import React from "react";
import AuctionIcon, { SALE_TYPES } from "../../info-block/icon";
import useSheet from "react-jss";
import TextPlaceholder from "../../text-placeholder";

const iconType = type => {
	switch (type) {
		case "TaxSale":
			return SALE_TYPES.TAX;
		case "Adjudication":
			return SALE_TYPES.ADJ;
		case "SecondaryMarket":
			return SALE_TYPES.LIEN;
	}
};

const FilterIcon = ({ placeholder, name, value, classes }) =>
	name === "saleType" ? (
		placeholder ? (
			<TextPlaceholder length={1} />
		) : (
			<AuctionIcon
				className={classes.icon}
				size="1.5em"
				auctionType={iconType(value)}
			/>
		)
	) : null;

const styles = {
	icon: {
		display: "inline-block",
		verticalAlign: "top",
		position: "relative",
		top: "0.25em"
	}
};

export default useSheet(styles)(FilterIcon);
