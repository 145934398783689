import { useCallback } from "react";

const useDebounceHideMenu = ({
	openMenu,
	setMouseInside,
	mouseInside,
	hideMenu,
	mapView
}) => {
	return useCallback(() => {
		if (openMenu) {
			setMouseInside(null);
			setTimeout(() => {
				if (!mouseInside) {
					hideMenu();
					if (mapView) {
						setTimeout(() => {
							document
								.getElementsByClassName("navbar")[0]
								.setAttribute("style", "opacity: .75");
						}, 300);
					}
				}
			}, 500);
		} else if (mapView) {
			document
				.getElementsByClassName("navbar")[0]
				.setAttribute("style", "opacity: .75");
		}
	}, [hideMenu, mapView, mouseInside, openMenu, setMouseInside]);
};

export default useDebounceHideMenu;
