import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { debounce } from "lodash";

import {
	auctionBiddingStatusChanged,
	auctionBidSaved
} from "../auction/actions";
import * as bid from "../bid/actions";

import { useMessageHandler } from "./context";

const useChannelDispatcher = () => {
	const dispatch = useDispatch();

	const actionsToDispatch = useRef([]);
	const dispatchActions = useCallback(
		debounce(() => {
			dispatch(actionsToDispatch.current);
			actionsToDispatch.current = [];
		}, 200),
		[dispatch]
	);

	const onMessage = useCallback(
		msg => {
			switch (msg.type) {
				case "AuctionStatus":
					actionsToDispatch.current.push(auctionBiddingStatusChanged(msg));
					dispatchActions();
					break;

				case "BidSaved":
					actionsToDispatch.current.push(auctionBidSaved(msg));
					dispatchActions();
					break;

				case "BidAccepted":
					actionsToDispatch.current.push(bid.bidAccepted(msg));
					dispatchActions();
					break;

				case "BidRejected":
					actionsToDispatch.current.push(bid.bidRejected(msg));
					dispatchActions();
					break;

				case "BidCanceled":
					actionsToDispatch.current.push(bid.bidCanceled(msg));
					dispatchActions();
					break;

				case "BidNotCanceled":
					actionsToDispatch.current.push(bid.bidNotCanceled(msg));
					dispatchActions();
					break;
			}
		},
		[dispatchActions]
	);

	useMessageHandler(onMessage);
};

export default useChannelDispatcher;
