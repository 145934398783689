import React from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "@civicsource/users";
import BidSaveInput from "./input";
import BidSaveButtonContents from "./save-button-contents";
import BidDeleteButtonContents from "./delete-button-contents";
import useSavedBid from "./saved-bid-hook";
import { normalizePercentagesForCalculations } from "../normalize-percentages";
// import { CheckCircleOutline } from "@material-ui/icons";
import { purple } from "@material-ui/core/colors";
import ThemedInputGroup from "../../themed-input-group";

const BidSave = ({ startingBid, userSavedBid, lotNumber, user, isMoney }) => {
	const {
		amount,
		setAmount,
		isSaving,
		isSaved,
		saveError,
		isDeleting,
		isDeleted,
		handleChange,
		handleSave,
		handleBlur,
		handleDelete
	} = useSavedBid({
		userSavedBid,
		lotNumber,
		startingBid,
		user,
		isMoney
	});

	const bidSaveInput = (
		<BidSaveInput
			{...{
				amount,
				setAmount,
				startingBid,
				userSavedBid,
				lotNumber,
				isSaving,
				handleChange,
				handleBlur,
				isMoney
			}}
		/>
	);

	const showSaveButton =
		!userSavedBid ||
		userSavedBid !== normalizePercentagesForCalculations(amount, isMoney);

	return (
		<form noValidate onSubmit={showSaveButton ? handleSave : handleDelete}>
			<ThemedInputGroup
				color={purple}
				// labelChildren={
				// 	userSavedBid ? (
				// 		<>
				// 			Saved{" "}
				// 			<span style={{ color: purple[300] }}>
				// 				<CheckCircleOutline style={{ fontSize: 14 }} />
				// 			</span>
				// 		</>
				// 	) : isSaving ? (
				// 		"Saving"
				// 	) : isDeleting ? (
				// 		"Deleting"
				// 	) : (
				// 		"Save"
				// 	)
				// }
				inputChildren={bidSaveInput}
				buttonChildren={
					showSaveButton ? (
						<BidSaveButtonContents {...{ isSaving, isSaved, saveError }} />
					) : (
						<BidDeleteButtonContents
							{...{
								userSavedBid,
								saveError,
								handleDelete,
								isDeleting,
								isDeleted
							}}
						/>
					)
				}
				buttonProps={{ type: "submit", disabled: isSaving || isDeleting }}
			/>
		</form>
	);
};

const connectUser = component => {
	return connect(state => {
		const currentUser = getCurrentUser(state);
		return currentUser;
	})(component);
};

export default connectUser(BidSave);
