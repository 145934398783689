import React, { useCallback, useEffect } from "react";
import { useFetch, useLazyFetch } from "react-fetch-hooks";

import ColorizedOutlineButton from "../../colorized-outline-button";
import { COLOR_BIDDER } from "../../colors";
import { formatNumber, formatMoney } from "accounting";
import { get } from "lodash";

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grow,
	Typography,
	CircularProgress
} from "@material-ui/core";

import { useBulkBiddingInProgress } from "./progress";

import { useSelector } from "react-redux";
import { getCurrentUser } from "@civicsource/users";

const BULK_URL = `${process.env.BIDDING_API_URL}bulk/`;

const GrowDown = props => (
	<Grow style={{ transformOrigin: "50% 0 0" }} {...props} />
);

const useFetchBulkBidding = ({ user, doFetch }) => {
	const bearerToken = get(user, "token");
	return useFetch(bearerToken && doFetch ? BULK_URL : null, {
		bearerToken,
		refreshInterval: 5000
	});
};

const usePlaceBulkBids = ({ readyCount, readyAmount, user }) => {
	const bearerToken = get(user, "token");

	const { setIsSessionInProgress, resetSession } =
		useBulkBiddingInProgress() || {};
	if (!setIsSessionInProgress) {
		throw new Error(
			"The BulkBidConfirmation component requires the BulkBidderInProgressProvider as an ancestor."
		);
	}

	const { fetch: postBulkBids, headers, error } = useLazyFetch(BULK_URL, {
		bearerToken,
		method: "POST",
		body: JSON.stringify({ readyCount, readyAmount })
	});

	const bulkSessionUrl = headers ? headers.get("location") : null;
	useEffect(() => {
		setIsSessionInProgress(bulkSessionUrl);
	}, [bulkSessionUrl, setIsSessionInProgress]);

	const placeBulkBids = useCallback(() => {
		setIsSessionInProgress(true);
		postBulkBids();
	}, [postBulkBids, setIsSessionInProgress]);

	const bidSummaryChanged = get(error, "response.status") === 409;

	if (bidSummaryChanged) {
		resetSession();
	}

	return {
		placeBulkBids,
		readyCount: bidSummaryChanged
			? get(error, "response.body.readyCount")
			: null,
		readyAmount: bidSummaryChanged
			? get(error, "response.body.readyAmount")
			: null
	};
};

const BulkBidConfirmation = ({
	isDialogOpen,
	handleCloseDialog,
	handleOpenDialog
}) => {
	const user = useSelector(getCurrentUser);

	const { body: bulkBiddingSummary, isFetched } = useFetchBulkBidding({
		user,
		doFetch: isDialogOpen
	});

	const readyCount = get(bulkBiddingSummary, "readyCount");
	const readyAmount = get(bulkBiddingSummary, "readyAmount");

	const {
		placeBulkBids,
		readyCount: updatedReadyCount,
		readyAmount: updatedReadyAmount
	} = usePlaceBulkBids({
		readyCount,
		readyAmount,
		user
	});

	const isMismatch = updatedReadyCount || updatedReadyAmount;

	useEffect(() => {
		if (isMismatch) {
			handleOpenDialog();
		}
	}, [handleOpenDialog, isMismatch]);

	const handleSubmit = useCallback(() => {
		placeBulkBids();
		handleCloseDialog();
	}, [handleCloseDialog, placeBulkBids]);

	return (
		<Dialog
			onClose={handleCloseDialog}
			open={isDialogOpen}
			maxWidth="lg"
			scroll="body"
			TransitionComponent={GrowDown}
			aria-labelledby="bulk-bidder-title"
		>
			<DialogTitle id="bulk-bidder-title">Place Saved Bids</DialogTitle>
			<DialogContent
				style={{
					minHeight: "50vh",
					textAlign: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center"
				}}
			>
				<ConfirmationBody
					readyAmount={updatedReadyAmount || readyAmount}
					readyCount={updatedReadyCount || readyCount}
					isFetched={isFetched}
					isMismatch={isMismatch}
				/>
			</DialogContent>
			<DialogActions>
				<ConfirmationAction
					readyCount={readyCount}
					handleCloseDialog={handleCloseDialog}
					handleSubmit={handleSubmit}
				/>
			</DialogActions>
		</Dialog>
	);
};

const ConfirmationBody = ({
	readyCount,
	readyAmount,
	isFetched,
	isMismatch
}) => {
	if (!isFetched) {
		return (
			<Typography gutterBottom color="textSecondary" variant="h4">
				<CircularProgress />
			</Typography>
		);
	}

	if (!readyCount) {
		return (
			<>
				<Typography gutterBottom color="textSecondary" variant="h4">
					None of your saved auctions are open yet.
				</Typography>

				<Typography gutterBottom color="textSecondary" variant="h4">
					Keep this open to be notified when you can place your saved bids.
				</Typography>
			</>
		);
	}

	return (
		<>
			{isMismatch ? (
				<Typography gutterBottom variant="h4">
					Active saved auctions have changed. <br />
					Review your bids and place them again.
				</Typography>
			) : null}
			<Typography gutterBottom color="textSecondary" variant="h4">
				You are about to place
			</Typography>
			<Typography gutterBottom variant="h2">
				{formatNumber(readyCount)} bids
			</Typography>
			<Typography gutterBottom color="textSecondary" variant="h4">
				for a total of
			</Typography>
			<Typography gutterBottom variant="h2">
				{formatMoney(readyAmount)}
			</Typography>
		</>
	);
};

const ConfirmationAction = ({
	readyCount,
	handleCloseDialog,
	handleSubmit
}) => {
	if (!readyCount) {
		return (
			<Button size="large" onClick={handleCloseDialog}>
				Close
			</Button>
		);
	}

	return (
		<ColorizedOutlineButton
			color={COLOR_BIDDER}
			size="large"
			onClick={handleSubmit}
			fullWidth
		>
			Place all bids
		</ColorizedOutlineButton>
	);
};

export default BulkBidConfirmation;
