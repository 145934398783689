import React from "react";
import { CircularProgress } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import IconHolder from "./icon-holder";

const BidDeleteButton = ({
	isSavedBidFetching,
	userSavedBid,
	saveError,
	isDeleting,
	isDeleted
}) => {
	const deleteButtonTitle = isDeleted
		? "Deleted!"
		: isDeleting
		? "Deleting"
		: "Delete saved bid";

	return (
		<IconHolder>
			{saveError ? (
				<ErrorOutline titleAccess="Error deleting" />
			) : isSavedBidFetching ? (
				<CircularProgress size={22} />
			) : userSavedBid ? (
				<div title={deleteButtonTitle}>
					{isDeleting ? (
						<CircularProgress size={22} title={deleteButtonTitle} />
					) : (
						<TrashIcon />
					)}
				</div>
			) : null}
		</IconHolder>
	);
};

export default BidDeleteButton;
