import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline"
	}
});

export const PropertyIcon = styled(
	({ classes, isLargeProperty, isDetailsIcon }) => (
		<svg
			style={
				isDetailsIcon
					? { width: "2.5em", height: "2.5em", marginTop: "3px" }
					: !isLargeProperty
					? { width: "1em", height: "1em", marginTop: "5px" }
					: { width: "2.9em", height: "2.9em" }
			}
			className={classes.icon}
			viewBox="0 0 48 41"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Home-Page"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Search-Results---List-View"
					transform="translate(-586.000000, -849.000000)"
					stroke="#0F70FF"
				>
					<g
						id="List-View-Item-Copy-8"
						transform="translate(544.000000, 826.000000)"
					>
						<g
							id="property_icon_48px"
							transform="translate(42.000000, 21.000000)"
						>
							<g
								id="property_icon_large-copy"
								transform="translate(1.000000, 3.000000)"
							>
								<polyline
									id="Path-7"
									strokeLinecap="round"
									strokeLinejoin="round"
									points="-1.02140518e-13 20 23.1222562 0 46 20"
								/>
								<line x1="43" y1="42" x2="43" y2="17.293335" id="Path-12" />
								<line x1="3" y1="42" x2="3" y2="17.4561768" id="Path-12-Copy" />
								<path
									d="M6,15 L6,0.937918611 C6,0.419920466 6.40294373,0 6.9,0 L11.1,0 C11.5970563,0 12,0.419920466 12,0.937918611 L12,9.64071247"
									id="Path"
								/>
								<path
									d="M16,42 L16,24.8644068 C16,24.3870081 16.5372583,24 17.2,24 L28.8,24 C29.4627417,24 30,24.3870081 30,24.8644068 L30,42"
									id="Path"
								/>
								<line
									x1="0"
									y1="42"
									x2="46"
									y2="42"
									id="Path-6"
									strokeLinecap="round"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
);
