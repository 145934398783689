import React, { Component } from "react";
import { NavItem } from "react-bootstrap";

class TopNavDropdown extends Component {
	select = () => {
		this.props.handleSetOpenMenu(this.props);
	};
	render() {
		const { text, href, classes, selected } = this.props;
		return (
			<NavItem
				className={classes.navLeftLinks}
				style={
					selected
						? {
								background: "#434343"
						  }
						: null
				}
				key={text}
				href={href ? href : null}
				onClick={this.select}
			>
				{!href ? (
					<span className={classes.linkText}>
						{text}
						<i className="fa fa-fw fa-caret-down app-nav__mobile-drop-indicator" />
					</span>
				) : (
					<span className={classes.linkText}>{text}</span>
				)}
			</NavItem>
		);
	}
}

export default TopNavDropdown;
