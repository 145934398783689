import React from "react";
import { Link } from "gatsby";

const lotNumRegex = /^([A-Za-z]{3})(\d{3,8})$/;

const AuctionLink = ({ lotNumber, ...props }) => {
	if (!lotNumber) return null;

	const matches = lotNumRegex.exec(lotNumber);
	if (!matches) return null;
	const [, ta, num] = matches;

	const auctionRoute = `/auctions/${ta.toLowerCase()}/${num}`;

	return <Link {...props} to={auctionRoute} />;
};

export default AuctionLink;
