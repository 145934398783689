import { useCallback, useEffect } from "react";
import { useLazyFetch } from "react-fetch-hooks";
import { get, invoke } from "lodash";
import {
	normalizePercentagesForCalculations,
	normalizePercentagesForDisplay
} from "../../normalize-percentages";

const useSaveSavedBid = ({ amount, isMoney, setAmount, ...req }) => {
	const {
		isSaving,
		isSaved,
		handleSave,
		saveError,
		savedBidResults
	} = useHandleSave({
		isMoney,
		amount,
		...req
	});

	const newServerAmount = get(savedBidResults, "userSavedBid", null);

	useAfterSave({ isSaved, newServerAmount, setAmount, isMoney });

	return { handleSave, isSaving, isSaved, saveError };
};

const useHandleSave = ({ amount, isMoney, ...req }) => {
	const {
		isFetching: isSaving,
		isFetched: isSaved,
		error: saveError,
		fetch: saveBid,
		body: savedBidResults
	} = useLazyFetch({
		...req,
		method: "PATCH",
		body: JSON.stringify({
			userSavedBid:
				amount == null
					? null
					: normalizePercentagesForCalculations(amount, isMoney)
		}),
		resetDelay: 500
	});

	const handleSave = useCallback(
		evt => {
			invoke(evt, "preventDefault");
			saveBid();
		},
		[saveBid]
	);

	return {
		isSaving,
		isSaved,
		saveError,
		savedBidResults,
		handleSave
	};
};

const useAfterSave = ({ isSaved, newServerAmount, setAmount, isMoney }) => {
	useEffect(() => {
		if (isSaved && newServerAmount) {
			setAmount(normalizePercentagesForDisplay(newServerAmount, isMoney));
		}
	}, [isMoney, isSaved, newServerAmount, setAmount]);
};

export default useSaveSavedBid;
