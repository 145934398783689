import React from "react";
import useSheet from "react-jss";
import AuctionIcon from "./icon";
import Highlighter from "../highlighter";
import { Link } from "gatsby";
import AuctionLink from "../auction/link";
import { LOC_VALUES, filterQuery } from "../search/filters/filter-link";
import TextPlaceholder from "../text-placeholder";
import address1Placeholder from "./placeholder-data/address1";
import taxAuthorityNamePlaceholder from "./placeholder-data/tax-authority-name";
import { robotoStack } from "../typography";

const AuctionInfoBlock = ({
	placeholder,
	placeholderSeed = 0,
	auctionType,
	address,
	isRecent,
	lotNumber,
	taxAuthority,
	url = "/auctions",
	classes,
	searchWords = [],
	cityFipsCode,
	countyFipsCode,
	stateFipsCode,
	containerComponent: Container = "div"
}) => {
	const isStateBasedJurisdiction =
		stateFipsCode && !countyFipsCode && !cityFipsCode;

	return (
		<Container className={classes.container}>
			<div className={classes.iconHolder}>
				<AuctionIcon
					placeholder={placeholder}
					{...{ auctionType }}
					style={{ fontSize: 36 }} // Does not apply to component if isLargeInvestment
					isLargeInvestment={auctionType === "TaxSale"}
					isLargeProperty={auctionType === "Adjudication"}
				/>
			</div>
			<div className={classes.watchlistHolder} />
			<div className={classes.body}>
				<AuctionLink
					className={classes.quietLink}
					lotNumber={lotNumber}
					state={{ referrer: url }}
				>
					<span className={classes.identifier}>
						{placeholder ? (
							<TextPlaceholder
								likePhrase={address1Placeholder[placeholderSeed]}
							/>
						) : (
							<Highlighter
								textToHighlight={
									(address &&
										address.address1 &&
										address.address1.toLowerCase()) ||
									"Unspecified"
								}
								{...{ searchWords }}
							/>
						)}
					</span>{" "}
					{isRecent && <span className={classes.newBadge}>new</span>}
				</AuctionLink>
				<br />
				{!isStateBasedJurisdiction ? (
					<Link
						to={
							placeholder
								? "#"
								: filterQuery({
										name: cityFipsCode
											? LOC_VALUES.CITY
											: LOC_VALUES.POLITICAL_SUB_DIVISION,
										url,
										value: cityFipsCode ? cityFipsCode : countyFipsCode,
										politicalSubDivision: countyFipsCode
								  })
						}
						className={["small", classes.cityStateLink].join(" ")}
					>
						{placeholder ? (
							<TextPlaceholder
								likePhrase={taxAuthorityNamePlaceholder[placeholderSeed]}
							/>
						) : address && address.city ? (
							address.city.toLowerCase()
						) : taxAuthority && taxAuthority.name ? (
							taxAuthority.name.toLowerCase()
						) : null}
					</Link>
				) : null}
				{!isStateBasedJurisdiction &&
				placeholder ? null : !isStateBasedJurisdiction ? (
					<span style={{ color: "#A4A1A1" }}>, </span>
				) : null}
				<Link
					to={
						placeholder
							? "#"
							: filterQuery({
									name: LOC_VALUES.STATE,
									url,
									value: stateFipsCode
							  })
					}
					className={["small", classes.cityStateLink].join(" ")}
				>
					{placeholder ? (
						<TextPlaceholder likePhrase="AA" />
					) : address && address.state ? (
						address.state.value
					) : null}
				</Link>{" "}
				<br className="hidden-xs" />
			</div>
		</Container>
	);
};

const styles = {
	container: {
		position: "relative"
	},
	iconHolder: {
		position: "absolute"
	},
	watchlistHolder: {
		position: "absolute",
		top: "2.875em",
		zIndex: 2
	},
	body: {
		marginLeft: 45,
		paddingLeft: "5px",
		fontSize: "1.12rem"
	},
	quietLink: {
		"&, &:hover, &:focus": {
			textDecoration: "none"
		}
	},
	identifier: {
		...robotoStack,
		height: "21px",
		width: "172px",
		fontSize: "18px",
		letterSpacing: "0.13px",
		lineHeight: "21px",
		textTransform: "capitalize",
		color: "#4d4d4d",
		"&:hover, &:focus": {
			color: "#222"
		}
	},
	newBadge: {
		padding: [1, 2],
		fontSize: 11,
		textTransform: "uppercase",
		color: "#ffffff",
		background: "#cc3c55",
		position: "relative",
		top: -10,
		border: {
			width: 1,
			style: "solid",
			color: "#cc3c55",
			radius: 3
		}
	},
	cityStateLink: {
		color: "#A4A1A1",
		textTransform: "capitalize",
		height: "24px",
		width: "62px",
		...robotoStack,
		fontSize: "18px",
		lineHeight: "21px"
	}
};

export default useSheet(styles)(AuctionInfoBlock);
