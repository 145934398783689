import { useState, useEffect } from "react";
import {
	formatDisplayValue,
	normalizePercentagesForDisplay
} from "../../normalize-percentages";

const useInitializeSavedBid = ({ startingBid, userSavedBid, isMoney }) => {
	const [amount, setAmount] = useState();

	const amountFromProps = Number(userSavedBid || (isMoney ? startingBid : 1));
	useEffect(() => {
		setAmount(
			formatDisplayValue(
				normalizePercentagesForDisplay(amountFromProps, isMoney),
				isMoney
			)
		);
	}, [amountFromProps, isMoney, startingBid, userSavedBid]);

	return { amount, setAmount };
};

export default useInitializeSavedBid;
