import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize, normalizeNew } from "../../normalize";

export default createReducer(Immutable({}), {
	onBidFetch(state, { bidId }) {
		return state.set(bidId, init(bidId).set("isLoading", true));
	},

	onBidFetchCompleted(state, bid) {
		const existingBid = state[bid.id];
		return state.set(bid.id, existingBid.merge(defang(bid)));
	},

	onBidFetchFailed(state, err, { bidId }) {
		const existingBid = state[bidId];
		return state.set(
			bidId,
			existingBid.merge({
				id: bidId,
				isLoading: false,
				isError: true
			})
		);
	},

	onAuctionBiddingInfoFetchCompleted(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onBidHistoryFetchCompleted(state, bids) {
		return this.onBidHistory(state, bids);
	},

	onBidHistoryFetchMoreCompleted(state, bids) {
		return this.onBidHistory(state, bids);
	},

	onBidHistory(state, bids) {
		if (bids) {
			bids.forEach(bidData => {
				const key = bidData.id;
				const bid = (state[key] || Immutable({})).merge(defang(bidData));
				state = state.set(key, bid);
			});
		}

		return state;
	},

	onAuctionBiddingStatusChanged(state, auction) {
		let newState = state;

		newState = applyBidToState(newState, auction.winningBid);
		newState = applyBidToState(newState, auction.userCurrentBid);

		return this.onBidHistory(newState, auction.latestBids);

		function applyBidToState(state, bid) {
			if (bid) {
				const key = bid.id;
				const theBid = (state[key] || Immutable({})).merge(defang(bid));
				return state.set(key, theBid);
			}

			return state;
		}
	},

	onAuctionBidSaved(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onBidCanceled(state, auction) {
		let result = state;

		auction.canceledBids.forEach(bid => {
			const existing = result[bid.id] || Immutable({});
			result = result.set(bid.id, existing.merge(bid));
		});

		return this.onAuctionBiddingStatusChanged(result, auction);
	},

	onBidAccepted(state, auction) {
		let bid = (state[auction.userCurrentBid.id] || Immutable({})).merge([
			defang(auction.userCurrentBid),
			{
				isSubmitting: false,
				isSubmitted: true,
				isAccepted: true
			}
		]);
		const newState = state.set(auction.userCurrentBid.id, bid);

		return this.onAuctionBiddingStatusChanged(newState, auction);
	},

	onBidSubmit(state, { lotNumber, amount }) {
		return state.set(normalizeNew(lotNumber), {
			lotNumber: normalize(lotNumber),
			amount,
			isSubmitting: true,
			isSubmitted: false,
			isError: false,
			isAccepted: false,
			isRejected: false
		});
	},

	onBidSubmitFailed(state, { response }, { lotNumber }) {
		return mergeWithCurrent(
			state,
			normalizeNew(lotNumber),
			{
				isSubmitting: false,
				isRejected: true,
				rejectionReason: evalRejectionReason(response)
			},
			init
		);

		function evalRejectionReason(response) {
			if (!response) return "Connection";

			switch (response.status) {
				case 401:
					return "Unauthorized";
				case 403:
					return "Forbidden";
				default:
					return "ServerError";
			}
		}
	},

	onBidSubmitCompleted(state, bid, { lotNumber }) {
		// sometimes the accepted message will come before the submitted message
		// in that case, check to see if the bid is already stored with a bid ID
		let theBid = state[bid.id] || state[normalizeNew(lotNumber)];

		theBid = theBid.merge([
			defang(bid),
			{
				isSubmitting: false,
				isSubmitted: true
			}
		]);

		return state.set(bid.id, theBid).without(normalizeNew(lotNumber));
	},

	onBidRejected(state, bid) {
		return mergeWithCurrent(
			state,
			bid.id,
			defang(bid).merge({
				isRejected: true
			}),
			init
		);
	},

	onUserAuctionsFetchCompleted(state, auctions) {
		auctions.items.forEach(auction => {
			state = this.onAuctionBiddingStatusChanged(state, auction, null);
		});

		return state;
	}
});

function init(bidId) {
	return Immutable({
		id: bidId,
		isLoading: false,
		isLoaded: false,
		isError: false
	});
}

function defang(bid) {
	let objToMerge = {
		isLoaded: true,
		isLoading: false,
		isError: false
	};

	if (bid.time) {
		objToMerge.time = new Date(bid.time);
	}

	return Immutable(bid).merge(objToMerge);
}

export function getBid(state, bidId) {
	return state[bidId];
}
