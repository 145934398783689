import { useCallback } from "react";
import { findDOMNode } from "react-dom";

const useHideMobileMenu = _navToggle => {
	return useCallback(() => {
		/* eslint-disable react/no-find-dom-node */
		const navToggle = findDOMNode(_navToggle.current);
		if (navToggle && !navToggle.classList.contains("collapsed")) {
			navToggle.click();
		}
	}, [_navToggle]);
};

export default useHideMobileMenu;
