import { useCallback } from "react";

const useSetNavStyleForMap = setMapView =>
	useCallback(() => {
		const isMapMode = document
			.getElementsByTagName("html")[0]
			// this class is set by /src/client/legacy/map/fullscreen/main.js
			.classList.contains("map-search-mode");

		setMapView(!!isMapMode);
	}, [setMapView]);

export default useSetNavStyleForMap;
