import { checkStatus, parseJSON, batchFetch } from "fetch-helpers";
import queryString from "query-string";

function checkUrl() {
	const url = process.env.BIDDING_API_URL;

	if (!url) {
		throw new Error("BIDDING_API_URL is not configured.");
	}

	return url;
}

export const getAuction = batchFetch(
	"lotNumber",
	(chunk, auth) =>
		fetch(`${checkUrl()}auctions/${chunk.join(",")}/`, {
			method: "GET",
			headers: populateHeaders(auth)
		}),
	{
		maxBatchSize: 30 // the server can handle loading roughly up to 30 auctions at a time
	}
);

export function getBid(lotNumber, bidId, auth) {
	return fetch(`${checkUrl()}auctions/${lotNumber}/bids/${bidId}/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function getBidHistory(lotNumber, auth) {
	return fetch(`${checkUrl()}auctions/${lotNumber}/bids/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function getBidHistoryOlderThan(lotNumber, olderThan, auth) {
	return fetch(
		`${checkUrl()}auctions/${lotNumber}/bids/?olderThanBidId=${olderThan}`,
		{
			method: "GET",
			headers: populateHeaders(auth)
		}
	)
		.then(checkStatus)
		.then(parseJSON);
}

export function getActiveAuctionsForUser(
	username,
	{ page = 1, pageSize = 10, type = "winning" },
	auth
) {
	const url = `${checkUrl()}auctions/page/${type}/?${queryString.stringify({
		page,
		pageSize
	})}`;

	return fetch(url, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function submitBid({ lotNumber, amount }, auth) {
	return fetch(`${checkUrl()}auctions/${lotNumber}/bids/`, {
		method: "POST",
		headers: populateHeaders(auth),
		body: JSON.stringify({ amount })
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function cancelBid({ lotNumber, bidId, reason, cancelAll }, auth) {
	return fetch(
		`${checkUrl()}auctions/${lotNumber}/bids/${bidId}/cancellation`,
		{
			method: "POST",
			headers: populateHeaders(auth),
			body: JSON.stringify({
				reason: reason,
				cancelAll: !!cancelAll
			})
		}
	)
		.then(checkStatus)
		.then(parseJSON);
}

function populateHeaders(auth) {
	let headers = {
		Accept: "application/json",
		"Content-type": "application/json"
	};

	if (auth) {
		headers.Authorization = `Bearer ${auth.token}`;
	}

	return headers;
}
