import React from "react";

import {
	Card,
	CardContent,
	CardActions,
	Button,
	Typography
} from "@material-ui/core";

const Register = () => (
	<Card>
		<CardContent>
			<Typography variant="headline">Have you registered?</Typography>
			<Typography component="p">
				By <a href="/registration">signing up for a CivicSource account</a>, you
				will be able to bid during an auction as well as be able to save
				properties to your favorites.
			</Typography>
		</CardContent>
		<CardActions>
			<Button color="primary" href="/registration">
				Create Account
			</Button>
		</CardActions>
	</Card>
);

export default Register;
