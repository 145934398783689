import { useCallback } from "react";

const useHandleSetOpenMenu = ({ mapView, setOpenMenu, openMenu }) =>
	useCallback(
		opened => {
			if (opened.href) return;
			mapView
				? setOpenMenu(prevOpenMenu =>
						!prevOpenMenu
							? opened
							: opened.text === prevOpenMenu.text
							? null
							: openMenu
				  )
				: setOpenMenu(prevOpenMenu =>
						!prevOpenMenu
							? opened
							: opened.text === prevOpenMenu.text
							? null
							: opened
				  );
		},
		[mapView, openMenu, setOpenMenu]
	);

export default useHandleSetOpenMenu;
