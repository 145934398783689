import React, { Component } from "react";
import { navigate } from "@reach/router";
import { Grid } from "react-bootstrap";

export default class SearchBar extends Component {
	state = {
		searchText: ""
	};

	onSearchText = ({ target }) => {
		this.setState({ searchText: target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		navigate(`/auctions?q=${this.state.searchText}`);
	};

	render() {
		return (
			<Grid data-ko-app="navbar-items">
				<div className="row" style={{ paddingRight: "30px" }}>
					<div className="col-md-12">
						<form
							className="app-search__container"
							action="/auctions"
							method="get"
							role="search"
							id="search-form"
							onSubmit={this.onSubmit}
						>
							<div className="input-group">
								<input
									name="q"
									className="form-control"
									type="text"
									value={this.state.searchText}
									onChange={this.onSearchText}
								/>
								<div className="input-group-btn">
									<button type="submit" className="btn btn-primary">
										<span className="hidden-xs">Search</span>
										<span className="visible-xs">
											<i className="fa fa-search fa-flip-horizontal" />
											<span className="sr-only">Search</span>
										</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Grid>
		);
	}
}
