import { withProps } from "recompose";

const massagePlaceBid = withProps(({ auction, currentBid }) => {
	const isTarget = !!currentBid && auction.lotNumber === currentBid.lotNumber;
	const isSubmitting = isTarget && currentBid.isSubmitting;
	const isProcessing =
		isTarget &&
		currentBid.isSubmitted &&
		!currentBid.isAccepted &&
		!currentBid.isRejected;
	const isBidding = isSubmitting && isProcessing;
	const isRejected = isTarget && currentBid.isRejected;

	return {
		isTarget,
		isSubmitting,
		isProcessing,
		isBidding,
		isRejected
	};
});

export default massagePlaceBid;
