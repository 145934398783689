import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "15px",
		height: "15px"
	}
});

export const MoIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="210mm"
		height="297mm"
		viewBox="0 0 210 297"
		version="1.1"
	>
		<g id="state_of_mo_15px" stroke="none" strokeWidth="10" fill="none">
			<path
				id="path7041"
				stroke="#7133FF"
				strokeWidth="10"
				d="M 6.4255953,2.901588 H 141.36309 l 9.07143,11.882761 -11.52828,17.031958 8.88245,19.40851 20.41071,47.153067 11.71726,-10.694482 12.8512,6.733563 -19.65476,38.024835 27.59225,71.29657 -3.40179,25.34988 9.44941,25.3499 -18.52083,15.82558 -3.40179,24.55769 h -27.59226 l 5.85863,-14.45735 -6.23661,-9.70425 L 23.812499,271.0559 24.190475,91.626204 3.4017854,61.523211 19.654761,36.965504 Z"
			/>
		</g>
	</svg>
));
