import React from "react";
import { PropTypes as t } from "prop-types";
import { WaitingIndicator as Wait } from "@civicsource/ui";
import { ReactComponent as BidderIcon } from "../../icons/bidder.svg";
import IconHolder from "../save/icon-holder";

const BidButtonContents = ({
	verbose = false,
	startText = "Place Bid",
	activeText = "Placing Bid",
	isSubmitting,
	isProcessing
}) => {
	const isBidding = isSubmitting || isProcessing;
	const buttonText = isBidding ? activeText : startText;

	const spinner = isBidding ? (
		<div style={{ display: "inline-block" }}>
			<Wait reverse={isProcessing} />
		</div>
	) : null;

	if (verbose) {
		return (
			<span>
				{spinner} {buttonText}
			</span>
		);
	}

	return (
		<span title={buttonText}>
			{isBidding ? (
				spinner
			) : (
				<IconHolder>
					<BidderIcon />
				</IconHolder>
			)}
		</span>
	);
};

BidButtonContents.propTypes = {
	isSubmitting: t.bool.isRequired,
	isProcessing: t.bool.isRequired,
	text: t.string,
	activeText: t.string
};

export default BidButtonContents;
