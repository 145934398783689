import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import BidItem from "./bid";
import ShowMoreBids from "./show-more";

const BidHistory = ({ classes, auction, getUserUrl, fetchMoreBidHistory }) => {
	const { bids } = auction;
	const areAllBidsLoaded =
		auction.bidCount + auction.rejectedBidCount === bids.items.length;

	const showMoreButton = areAllBidsLoaded ? null : (
		<ShowMoreBids
			lotNumber={auction.lotNumber}
			bids={bids}
			onClick={fetchMoreBidHistory}
			isLoadingMoreBids={bids.isLoading && bids.isLoaded}
		/>
	);

	const bidItemTransitionNames = {
		enter: classes.enter,
		exit: classes.exit,
		enterActive: classes.active,
		exitActive: classes.active
	};

	return (
		<div>
			<table className="table" role="log">
				<thead>
					<tr>
						<th>Bidder</th>
						<th className="text-right">Bid</th>
						<th className="text-center">Time</th>
					</tr>
				</thead>
				<TransitionGroup
					component="tbody"
					aria-live="polite"
					aria-atomic="false"
				>
					{bids.items.map(bid => (
						<CSSTransition
							key={bid.id}
							classNames={bidItemTransitionNames}
							timeout={{ enter: 500, exit: 500 }}
						>
							<BidItem
								bid={bid}
								auction={auction}
								classes={classes}
								getUserUrl={getUserUrl}
							/>
						</CSSTransition>
					))}
				</TransitionGroup>
			</table>
			{showMoreButton}
		</div>
	);
};

export default BidHistory;
