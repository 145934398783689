import React from "react";
import useSheet from "react-jss";
import { onlyUpdateForKeys } from "recompose";
import { words } from "lodash";

let delay = 0;

const roundToNPlaces = (num, n = 2) => Math.round(num * (10 * n)) / (10 * n);

const getDefaultLength = (base, range) =>
	Math.round(base + Math.random() * range);

// This component uses a combination of em-based units
// and the currentColor keyword, so drop it into the contents
// of e.g. a typographic element or link and it will inherit
// the metrics & colors of text which would otherwise be prsent.
const _TextPlaceholder = ({
	baseLength = 9,
	lengthRange = 0,
	likePhrase = "",
	length = getDefaultLength(baseLength, lengthRange),
	fullWidth,
	animate = false,
	classes
}) => {
	const placeholderProp = likePhrase
		? words(likePhrase.replace(/[.,]/g, "")).map(p => p.length)
		: length;
	if (Array.isArray(placeholderProp)) {
		return (
			<>
				{placeholderProp.map((l, i) => (
					<TextPlaceholder key={`${l}-${i + 1}`} length={l} />
				))}{" "}
			</>
		);
	}

	if (delay < -1000) {
		delay = 0;
	}

	delay -= 10;

	return (
		<div
			className={classes.root}
			style={{
				width: fullWidth ? "100%" : `${roundToNPlaces(length * 0.55)}em`
			}}
		>
			<div className={classes.back} />
			<div
				style={{ animationDelay: `${delay}ms` }}
				className={animate ? classes.anim : classes.rect}
			/>
			<div
				style={{ animationDelay: `${delay}ms` }}
				className={animate ? classes.anim2 : classes.rect}
			/>
		</div>
	);
};

const styles = () => {
	return {
		"@keyframes textPlaceholderGlimmer": {
			"0%": {
				opacity: 1,
				transform: "translateX(-100%)"
			},
			"50%": {
				opacity: 0.375,
				transform: "translateX(0)"
			},
			"100%": {
				opacity: 1,
				transform: "translateX(100%)"
			}
		},
		root: {
			position: "relative",
			display: "inline-block",
			height: "1em",
			overflow: "hidden",
			opacity: 0.8,
			pointerEvents: "none",
			"& ~ $root": {
				marginLeft: "0.4em"
			}
		},
		rect: {
			display: "block",
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		back: {
			composes: "$rect",
			background: "currentColor",
			opacity: 0.375
		},
		anim: {
			composes: "$rect",
			right: "25%",
			left: "25%",
			background: "currentColor",
			animation: "750ms textPlaceholderGlimmer linear infinite alternate",
			filter: "blur(1em)"
		},
		anim2: {
			composes: "$rect",
			right: "35%",
			left: "35%",
			background: "currentColor",
			animation: "750ms textPlaceholderGlimmer ease-in-out infinite alternate",
			filter: "blur(0.75em)",
			opacity: 0.75
		}
	};
};

const styled = useSheet(styles);

const TextPlaceholder = styled(_TextPlaceholder);

export default onlyUpdateForKeys(["length", "likePhrase"])(TextPlaceholder);
