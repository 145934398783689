import cancellation, * as fromCancellation from "./cancellation";
import byId, * as fromById from "./by-id";
import current, * as fromCurrent from "./current";

import { combineReducers } from "redux-seamless-immutable";
import { createSelector } from "reselect";

import { registerReducer } from "redux-rsi";

const TREE_ROOT = "bidding/bid";

registerReducer(
	TREE_ROOT,
	combineReducers({
		cancellation,
		byId,
		current
	})
);

export const getBid = createSelector(
	(state, bidId) => fromById.getBid(state[TREE_ROOT].byId, bidId),
	(state, bidId) =>
		fromCancellation.getBidCancellation(state[TREE_ROOT].cancellation, bidId),
	(bid, cancel) => (bid ? bid.set("cancellation", cancel) : null)
);

export function getCurrentBid(state, lotNumber) {
	const currentBidId = fromCurrent.getCurrentBidId(
		state[TREE_ROOT].current,
		lotNumber
	);
	return getBid(state, currentBidId);
}
