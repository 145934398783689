import React from "react";
import useSheet from "react-jss";

const MenuLink = ({ hideMenu, classes, ...props }) => (
	<a className={classes.menuItem} onClick={hideMenu} {...props} />
);

const menuItemStyles = {
	menuItem: {
		color: "#cecece",
		display: "block",
		padding: [16, 0, 16, 40],
		"&:hover": {
			background: "#525252",
			color: "#cecece",
			textDecoration: "none"
		},
		"&:focus": {
			textDecoration: "none",
			color: "#cecece"
		},
		height: 50
	}
};
export default useSheet(menuItemStyles)(MenuLink);
