import { useCallback } from "react";

const useHandleSubmit = hideMenu => {
	return useCallback(
		e => {
			if (e.target === document.getElementById("search-form")) {
				hideMenu();
			}
		},
		[hideMenu]
	);
};

export default useHandleSubmit;
