import React from "react";
import BidCount from "./main";

const BidCountWrapper = ({
	auction,
	showLoading,
	customPlaceholder = <span>Loading bid count…</span>,
	errorMessage = auction ? <span>{auction.biddingInfoLoadError}</span> : null,
	noBidsMesssage = null,
	...props
}) => {
	if (auction.isBiddingInfoMissing) {
		return null;
	}

	if (!auction.isBiddingInfoLoaded && showLoading) {
		if (auction.biddingInfoLoadError) {
			return errorMessage;
		}

		return customPlaceholder;
	}

	if (auction.bidCount <= 0 && !auction.rejectedBidCount) {
		return noBidsMesssage;
	}

	return (
		<BidCount
			className="bid-count__container"
			bidCount={auction.bidCount}
			bidderCount={auction.bidderCount}
			rejectedBidCount={auction.rejectedBidCount}
			{...props}
		/>
	);
};

export default BidCountWrapper;
