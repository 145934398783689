import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchAuctionBiddingInfo } from "./actions";
import { getAuctionBiddingInfo } from "./reducer";

export default function connectAuction(component) {
	return connect(
		(state, { lotNumber }) => {
			let result = {};

			if (lotNumber) {
				result.auction = getAuctionBiddingInfo(state, lotNumber);
			}

			return result;
		},
		dispatch =>
			bindActionCreators(
				{
					fetchAuctionBiddingInfo
				},
				dispatch
			)
	)(component);
}
