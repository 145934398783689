import React from "react";
import InnerWrapper from "./inner-wrapper";
import UserHud from "../user-hud";
import AuctionList from "./auction-list";
import { useBulkBiddingInProgress } from "../user-hud/bulk-bidder/progress";
import useSheet from "react-jss";

const UserBidsRoot = ({
	type = "winning",
	totalCount,
	classes,
	location,
	username
}) => {
	const { isSessionInProgress } = useBulkBiddingInProgress();
	return (
		<>
			<UserHud activeTab={type.toUpperCase()} />
			<InnerWrapper>
				<div className={classes.itemsWrapper}>
					{
						<AuctionList
							type={type}
							totalCount={totalCount}
							username={username}
							isSessionInProgress={isSessionInProgress}
							location={location}
						/>
					}
				</div>
			</InnerWrapper>
		</>
	);
};

const styles = {
	itemsWrapper: {
		flex: "1 0 auto"
	}
};

export default useSheet(styles)(UserBidsRoot);
