import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		marginRight: "3px"
	}
});

export const CalendarIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		width="15px"
		height="15px"
		viewBox="0 0 15 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="calendar_15px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<rect
				id="Rectangle"
				stroke="#7133FF"
				strokeWidth="0.5"
				x="0.25"
				y="1.25"
				width="14.5"
				height="13.5"
				rx="0.5"
			/>
			<line
				x1="0"
				y1="4.51178436"
				x2="15"
				y2="4.51178436"
				id="Path-20"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
			<line
				x1="11.4414062"
				y1="0.296081543"
				x2="11.4414062"
				y2="2"
				id="Path-21"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinecap="round"
			/>
			<line
				x1="3.5"
				y1="0.296081543"
				x2="3.5"
				y2="2"
				id="Path-21-Copy"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinecap="round"
			/>
		</g>
	</svg>
));
