import { useFetch } from "react-fetch-hooks";
import { isArray } from "lodash";

// takes an array of lot numbers, fetches & returns the CivicSource auctions for them.
const useCivicSourceAuctionsInfo = (lotNumbers = []) => {
	const lotNumbersFormatted =
		lotNumbers && lotNumbers.length ? lotNumbers.join(",") : null;
	const { body: auctions } = useFetch(
		lotNumbersFormatted ? `/api/auctions/${lotNumbersFormatted}` : null
	);
	return isArray(auctions) ? auctions : [auctions];
};

export default useCivicSourceAuctionsInfo;
