/* global Rollbar */

import { get } from "lodash";

export default () => next => action => {
	if (global.Rollbar) {
		if (
			action.type === "USER_HYDRATE" ||
			action.type === "USER_AUTHENTICATE_COMPLETED"
		) {
			const { id, username, email } = get(action, "payload") || {};

			if (id) {
				Rollbar.configure({
					payload: {
						person: { id, username, email }
					}
				});
			}
		}
	}

	return next(action);
};
