import useNavbarState from "./state";
import useNavbarRefs from "./refs";
import useNavbarInit from "./init";

import useSetNavStyleForMap from "./nav-style-for-map";
import useMapNavHover from "./hover";

import useCheckWindowCollapsed from "./check-window-collapsed";
import useNavHandlers from "./handlers";

const useNavbar = ({ mapView, setMapView }) => {
	const {
		openMenu,
		setOpenMenu,
		mouseInside,
		setMouseInside,
		navbarOpen,
		setNavbarOpen,
		windowCollapsed,
		setWindowCollapsed
	} = useNavbarState();

	const {
		_navToggle,
		_navbar,
		navbarRef,
		navAreaRef,
		navToggleRef
	} = useNavbarRefs();

	const navHover = useMapNavHover(mapView);

	const {
		debounceHideMenu,
		handleMouseEnter,
		handleSetOpenMenu,
		handleSubmit,
		navbarCollapsed
	} = useNavHandlers({
		_navbar,
		_navToggle,
		mapView,
		mouseInside,
		openMenu,
		setMouseInside,
		setOpenMenu,
		windowCollapsed
	});

	const checkWindowCollapsed = useCheckWindowCollapsed(setWindowCollapsed);

	const setNavStyleForMap = useSetNavStyleForMap(setMapView);

	useNavbarInit({
		setNavStyleForMap,
		checkWindowCollapsed,
		navbarCollapsed,
		handleSubmit
	});

	return {
		openMenu,
		setOpenMenu,
		handleSetOpenMenu,
		windowCollapsed,
		setWindowCollapsed,
		mouseInside,
		setMouseInside,
		navbarOpen,
		setNavbarOpen,
		handleMouseEnter,
		navHover,
		debounceHideMenu,
		navbarRef,
		navAreaRef,
		navToggleRef
	};
};

export default useNavbar;
