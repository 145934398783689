import React from "react";
import Placeholder from "./placeholder";

const Placeholders = ({
	isLoading,
	isError,
	isCanceled,
	isEnded,
	isOpen,
	winningBid,
	userNextBidAmount,
	showPlaceholder
}) => {
	if (isError) return <span>Error loading auction...</span>;

	if (showPlaceholder) {
		if (isLoading) return <span>Loading...</span>;

		if (isCanceled) {
			return <Placeholder>The auction has been canceled.</Placeholder>;
		}

		if (!isOpen && !isEnded) {
			return <Placeholder>The auction has not started.</Placeholder>;
		}

		if (isEnded) {
			return (
				<Placeholder>
					The auction has concluded
					{winningBid ? "." : " without a winner."}
				</Placeholder>
			);
		}

		if (!userNextBidAmount) {
			return (
				<Placeholder>
					The minimum possible bid has already been placed.
				</Placeholder>
			);
		}
	}

	return null;
};

export default Placeholders;
