import React from "react";

import { Grid, withStyles } from "@material-ui/core";

import Login from "./login";
import Register from "./register";

import connect from "./connect";

const _EnforceLogin = ({ isAuthenticated, classes, children, ...props }) => {
	if (isAuthenticated) {
		return <div>{children}</div>;
	}

	return (
		<div className="container">
			<Grid container className={classes.root} justify="center" spacing={16}>
				<Grid item sm={4}>
					<Login {...props} />
				</Grid>
				<Grid item sm={8}>
					<Register />
				</Grid>
			</Grid>
		</div>
	);
};

const styles = theme => {
	return {
		"@global html": {
			fontSize: 16
		},
		root: {
			...theme.typography.body1,
			...theme.mixins.gutters({
				paddingTop: 16,
				paddingBottom: 16,
				marginTop: theme.spacing.unit * 3
			})
		},
		actions: {
			textAlign: "right"
		}
	};
};

const EnforceLogin = withStyles(styles)(connect(_EnforceLogin));

/* eslint-disable react/display-name */
export default Component => props => (
	<EnforceLogin>
		<Component {...props} />
	</EnforceLogin>
);
