import {
	getBid as apiGetBid,
	submitBid as apiSubmitBid,
	cancelBid as apiCancelBid
} from "../api";

import { createAjaxAction } from "redux-rsi";

import { getBid } from "./reducer";
import { getCurrentUser } from "@civicsource/users";

export function fetchBid(lotNumber, bidId) {
	return createAjaxAction(
		{
			type: "BID_FETCH",
			payload: {
				lotNumber,
				bidId
			}
		},
		getState => {
			const state = getState();

			const bid = getBid(state, bidId);
			if (bid && (bid.isLoaded || bid.isLoading || bid.isError)) {
				return;
			}

			return apiGetBid(lotNumber, bidId, getCurrentUser(state));
		}
	);
}

export function submitBid(lotNumber, amount) {
	const payload = { lotNumber, amount };

	return createAjaxAction(
		{
			type: "BID_SUBMIT",
			payload
		},
		getState => apiSubmitBid(payload, getCurrentUser(getState()))
	);
}

export function bidAccepted(msg) {
	return {
		type: "BID_ACCEPTED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}

export function bidRejected(msg) {
	return {
		type: "BID_REJECTED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}

export function cancelBid(lotNumber, bidId, reason, cancelAll) {
	const payload = { lotNumber, bidId, reason, cancelAll };

	return createAjaxAction(
		{
			type: "BID_CANCEL_QUEUE",
			payload
		},
		getState => apiCancelBid(payload, getCurrentUser(getState()))
	);
}

export function bidCanceled(msg) {
	return {
		type: "BID_CANCELED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}

export function bidNotCanceled(msg) {
	return {
		type: "BID_NOT_CANCELED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}
