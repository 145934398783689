import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Item from "./item";

const BidSummary = ({ auction, classes, ...props }) => {
	const bidItemTransitionNames = {
		enter: classes.enter,
		exit: classes.exit,
		enterActive: classes.active,
		exitActive: classes.active
	};

	return (
		<table className="table" role="log">
			<thead>
				<tr>
					<th>Bidder</th>
					<th className="text-right">Bid</th>
					<th className="text-center">Time</th>
				</tr>
			</thead>
			<TransitionGroup component="tbody" aria-live="polite" aria-atomic="false">
				{auction.bidSummary.map(bid => (
					<CSSTransition
						classNames={bidItemTransitionNames}
						key={bid.bidder}
						timeout={{ enter: 500, exit: 500 }}
					>
						<Item bid={bid} auction={auction} classes={classes} {...props} />
					</CSSTransition>
				))}
			</TransitionGroup>
		</table>
	);
};

export default BidSummary;
