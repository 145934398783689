module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"spuexrad","include_in_development":true,"delay_timeout":0},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"402913840663871"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layout/index.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/@civicsource/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"overrides":{"MuiCollapse":{"entered":{"height":"auto","overflow":"visible"}}},"typography":{"fontFamily":"'Roboto', sans-serif"},"palette":{"primary":{"50":"#e5edf2","100":"#bfd1e0","200":"#94b2cb","300":"#6993b6","400":"#487ca6","500":"#286596","600":"#245d8e","700":"#1e5383","800":"#184979","900":"#0f3768","A100":"#9cc5ff","A200":"#69a7ff","A400":"#368aff","A700":"#1d7bff","contrastDefaultColor":"light"}}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"className":"markdown-linked-header"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-15895299-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"361eae0f75d0444b835d4a66acaabd40","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"prod","client":{"javascript":{"source_map_enabled":true,"guess_uncaught_frames":true,"code_version":"066ff682753d220e1cdfbede8582cc38e8e6e23b"}}}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
