import React from "react";
import ColorizedOutlineButton from "../../colorized-outline-button";
import { COLOR_BIDDER } from "../../colors";

import { useBulkBiddingInProgress } from "./progress";

const BulkBidButton = ({ handleOpenDialog }) => {
	const { isSessionInProgress } = useBulkBiddingInProgress() || {};

	if (isSessionInProgress) {
		return null;
	}

	return (
		<ColorizedOutlineButton
			fullWidth
			onClick={handleOpenDialog}
			size="small"
			color={COLOR_BIDDER}
		>
			Place saved bids
		</ColorizedOutlineButton>
	);
};

export default BulkBidButton;
