import { createReducer } from "redux-rsi";
import Immutable from "seamless-immutable";

const initialState = Immutable({
	isQueuing: false,
	isQueued: false,
	isNotQueued: false,
	isCanceled: false,
	isNotCanceled: false
});

export default createReducer(Immutable({}), {
	onBidCancelQueue(state, { bidId }) {
		return state.set(
			bidId,
			initialState.merge({
				isQueuing: true
			})
		);
	},

	onBidCancelQueueCompleted(state, { id }) {
		const existingBid = state[id];
		return state.set(
			id,
			existingBid.merge({
				isQueuing: false,
				isQueued: true,
				isNotQueued: false,
				isCanceled: false,
				isNotCanceled: false
			})
		);
	},

	onBidCancelQueueFailed(state, err, { bidId }) {
		const bid = state[bidId];
		return state.set(
			bidId,
			bid.merge({
				isQueuing: false,
				isQueued: false,
				isNotQueued: true,
				isCanceled: false,
				isNotCanceled: false
			})
		);
	},

	onBidCanceled(state, auction) {
		let result = state;

		auction.canceledBids.forEach(bid => {
			const existing = result[bid.id] || Immutable({});
			result = result.set(
				bid.id,
				existing.merge({
					isQueuing: false,
					isQueued: false,
					isNotQueued: false,
					isCanceled: true,
					isNotCanceled: false
				})
			);
		});

		return result;
	},

	onBidNotCanceled(state, { id }) {
		const bid = state[id] || Immutable({});
		return state.set(
			id,
			bid.merge({
				isQueuing: false,
				isQueued: false,
				isNotQueued: false,
				isCanceled: false,
				isNotCanceled: true
			})
		);
	}
});

export function getBidCancellation(state, bidId) {
	return state[bidId] || initialState;
}
