import React from "react";

const PlaceBidConfirmationCoverAss = props => {
	const csAuction = props.csAuction;
	const isAdj =
		csAuction &&
		csAuction.auctionType &&
		csAuction.auctionType.toLowerCase() === "adjudication";
	const auctionTypeVerbiage = isAdj ? (
		<p>
			Bidder acknowledges that the opening bid price includes the cost for a
			title policy and associated endorsements with the minimum allowed coverage
			amount, and that he/she will be required to purchase additional coverage
			to equal the winning bid price by paying the difference in premium cost
			between the minimum and actual coverage amount at the act of sale.
		</p>
	) : null;

	return (
		<div>
			<p>
				By bidding you attest that:
				<br />
				You are not an employee, contractor or are otherwise affiliated with the
				seller or CivicSource.com.
				<br />
				You do not own or have an interest in this property.
			</p>
			{auctionTypeVerbiage}
		</div>
	);
};

export default PlaceBidConfirmationCoverAss;
