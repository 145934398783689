import { useEffect } from "react";
import { useSocketSend } from "./context";

const useChannelListen = lotNumber => {
	const send = useSocketSend();

	useEffect(() => {
		if (lotNumber) {
			send({ type: "Listen", body: lotNumber });
		}

		return () => {
			if (lotNumber) {
				send({ type: "Forget", body: lotNumber });
			}
		};
	}, [lotNumber, send]);
};

export default useChannelListen;
