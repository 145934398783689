import React from "react";
import useSheet from "react-jss";
import TextPlaceholder from "../text-placeholder";
import { COLOR_CERTIFICATES, COLOR_AUCTION_PROPERTY } from "../colors";
import { PropertyIcon } from "./auction-icon";

export const SALE_TYPES = {
	TAX: "TaxSale",
	ADJ: "Adjudication",
	LIEN: "Lien"
};

const AuctionIcon = ({
	placeholder,
	auctionType,
	classes,
	isLargeInvestment,
	isLargeProperty,
	isDetailsIcon
}) => {
	if (placeholder) {
		return <TextPlaceholder length={1} />;
	}

	return auctionType === SALE_TYPES.ADJ ? (
		<PropertyIcon
			isLargeProperty={isLargeProperty}
			isDetailsIcon={isDetailsIcon}
		/>
	) : (
		<InvestmentIcon
			classes={classes}
			isLargeInvestment={isLargeInvestment}
			isDetailsIcon={isDetailsIcon}
		/>
	);
};

export const InvestmentIcon = ({
	classes,
	isLargeInvestment,
	isDetailsIcon
}) => (
	<svg
		style={
			isDetailsIcon
				? { width: "2.5em", height: "2.5em", marginTop: "3px" }
				: !isLargeInvestment
				? { width: "1em", height: "1em", marginTop: "5px" }
				: { width: "2.9em", height: "2.9em" }
		}
		className={classes.icon}
		viewBox="0 0 160 160"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="investment_icon_160px"
			stroke="none"
			strokeWidth={isLargeInvestment ? "1" : "3px"}
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="investment_icon_large-copy"
				transform="translate(1.000000, 1.000000)"
				stroke="#08B681"
				strokeWidth={isLargeInvestment ? "2.5" : "9px"}
			>
				<g>
					<path
						d="M122,0 L155,0 C156.656854,-3.04359188e-16 158,1.34314575 158,3 L158,158 L158,158 L119,158 L119,3 C119,1.34314575 120.343146,3.04359188e-16 122,0 Z"
						id="Rectangle"
					/>
					<path
						d="M62,59 L96,59 C97.6568542,59 99,60.3431458 99,62 L99,158 L99,158 L59,158 L59,62 C59,60.3431458 60.3431458,59 62,59 Z"
						id="Rectangle-Copy-2"
					/>
					<path
						d="M3,107 L36,107 C37.6568542,107 39,108.343146 39,110 L39,158 L39,158 L0,158 L0,110 C-2.02906125e-16,108.343146 1.34314575,107 3,107 Z"
						id="Rectangle-Copy-3"
					/>
					<polyline
						id="Path-7-Copy"
						strokeLinecap="round"
						strokeLinejoin="round"
						points="0 74 85 0 59.5356115 0"
					/>
					<path d="" id="Path-13" />
					<line
						x1="85"
						y1="0"
						x2="85"
						y2="23"
						id="Path-14"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const styles = {
	Adjudication: {
		fill: COLOR_AUCTION_PROPERTY
	},
	TaxSale: {
		fill: COLOR_CERTIFICATES
	},
	icon: {
		display: "inline-block",
		verticalAlign: "baseline"
	}
};

export default useSheet(styles)(AuctionIcon);
