import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize } from "../../normalize";
import { get } from "lodash";

export default createReducer(Immutable({}), {
	onChannelListen(state, { lotNumber }) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isBiddingInfoLoading: true
			},
			init
		);
	},

	onAuctionBiddingInfoFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isBiddingInfoLoading: true
			},
			init
		);
	},

	onAuctionBiddingInfoFetchCompleted(state, auction, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			defang(auction).merge({
				isBiddingInfoLoading: false,
				isBiddingInfoLoaded: true
			}),
			init
		);
	},

	onAuctionBiddingInfoFetchFailed(state, { message, response }, lotNumber) {
		let result = {
			isBiddingInfoLoading: false,
			isBiddingInfoLoaded: false,
			biddingInfoLoadError: message
		};

		if (response && response.status == 404) {
			result.biddingInfoLoadError = null;
			result.isBiddingInfoMissing = true;
		}

		return mergeWithCurrent(state, normalize(lotNumber), result, init);
	},

	onAuctionBiddingStatusChanged(state, auction) {
		return mergeWithCurrent(
			state,
			normalize(auction.lotNumber),
			defang(auction).merge({
				isBiddingInfoLoading: false,
				isBiddingInfoLoaded: true,
				biddingInfoLoadError: null
			}),
			init
		);
	},

	onAuctionBidSaved(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onBidAccepted(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onBidRejected(state, bid) {
		const key = normalize(bid.lotNumber);

		const auction = state[key];

		if (!auction) {
			return state;
		}

		const newAuction = auction.set(
			"rejectedBidCount",
			auction.rejectedBidCount + 1
		);

		return state.set(key, newAuction);
	},

	onBidCanceled(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onUserAuctionsFetchCompleted(state, auctions) {
		let result = state;

		auctions.items.forEach(auction => {
			const key = normalize(auction.lotNumber);
			const currentAuction = state[key] || init(auction.lotNumber);
			result = result.set(
				key,
				currentAuction.merge(defang(auction)).merge({
					isBiddingInfoLoading: false,
					isBiddingInfoLoaded: true,
					biddingInfoLoadError: null
				})
			);
		});

		return result;
	}
});

function init(lotNumber) {
	return Immutable({
		lotNumber: normalize(lotNumber),

		isBiddingInfoLoading: false,
		isBiddingInfoLoaded: false,
		biddingInfoLoadError: null,
		isBiddingInfoMissing: false
	});
}

function defang(auction) {
	return (
		Immutable(auction)
			.set("userSavedBid", auction.userSavedBid || null)
			.without("latestBids")
			.without("canceledBids")
			.without("winningBid")
			.without("userCurrentBid")
			.set("winningBidId", get(auction, "winningBid.id", null))
			.set("userCurrentBidId", get(auction, "userCurrentBid.id", null))
			// don't conflict with auction.type that comes from the civicsource API, renaming this one to bidType
			.without("type")
			.set("bidType", auction.type)
			// the sale data from bidding API is just the sale GUID with TA PREFIX prepended
			// ignore that data and use the data from CivicSource API
			.without("sale")
	);
}

export function getBiddingInfo(state, lotNumber) {
	return state[normalize(lotNumber)] || init(lotNumber);
}
