import useHandleSetOpenMenu from "./handle-set-open-menu";
import useHandleSubmit from "./handle-submit";
import useHandleMouseEnter from "./handle-mouse-enter";
import useHideMobileMenu from "./hide-mobile-menu";
import useHideMenu from "./hide-menu";
import useDebounceHideMenu from "./debounce-hide-menu";
import useNavbarCollapsed from "./navbar-collapsed";

const useNavHandlers = ({
	_navbar,
	_navToggle,
	mapView,
	mouseInside,
	openMenu,
	setMouseInside,
	setOpenMenu,
	windowCollapsed
}) => {
	const hideMobileMenu = useHideMobileMenu(_navToggle);

	const hideMenu = useHideMenu({
		hideMobileMenu,
		mouseInside,
		openMenu,
		windowCollapsed,
		setOpenMenu
	});

	const debounceHideMenu = useDebounceHideMenu({
		openMenu,
		setMouseInside,
		mouseInside,
		hideMenu,
		mapView
	});

	const handleSetOpenMenu = useHandleSetOpenMenu({
		mapView,
		setOpenMenu,
		openMenu
	});
	const handleMouseEnter = useHandleMouseEnter(setMouseInside);
	const handleSubmit = useHandleSubmit(hideMenu);
	const navbarCollapsed = useNavbarCollapsed({ _navbar, hideMenu });

	return {
		debounceHideMenu,
		handleMouseEnter,
		handleSetOpenMenu,
		handleSubmit,
		hideMenu,
		navbarCollapsed
	};
};

export default useNavHandlers;
