// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-news-post-jsx": () => import("/opt/build/repo/src/news/post.jsx" /* webpackChunkName: "component---src-news-post-jsx" */),
  "component---src-content-page-jsx": () => import("/opt/build/repo/src/content/page.jsx" /* webpackChunkName: "component---src-content-page-jsx" */),
  "component---src-content-faq-index-jsx": () => import("/opt/build/repo/src/content/faq/index.jsx" /* webpackChunkName: "component---src-content-faq-index-jsx" */),
  "component---src-auction-index-js": () => import("/opt/build/repo/src/auction/index.js" /* webpackChunkName: "component---src-auction-index-js" */),
  "component---src-pages-auctions-js": () => import("/opt/build/repo/src/pages/auctions.js" /* webpackChunkName: "component---src-pages-auctions-js" */),
  "component---src-pages-bids-index-js": () => import("/opt/build/repo/src/pages/bids/index.js" /* webpackChunkName: "component---src-pages-bids-index-js" */),
  "component---src-pages-calendar-js": () => import("/opt/build/repo/src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-checkout-js": () => import("/opt/build/repo/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-help-js": () => import("/opt/build/repo/src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-orders-index-js": () => import("/opt/build/repo/src/pages/orders/index.js" /* webpackChunkName: "component---src-pages-orders-index-js" */),
  "component---src-pages-orders-new-js": () => import("/opt/build/repo/src/pages/orders/new.js" /* webpackChunkName: "component---src-pages-orders-new-js" */),
  "component---src-pages-sessions-js": () => import("/opt/build/repo/src/pages/sessions.js" /* webpackChunkName: "component---src-pages-sessions-js" */),
  "component---src-pages-support-general-js": () => import("/opt/build/repo/src/pages/support/general.js" /* webpackChunkName: "component---src-pages-support-general-js" */),
  "component---src-pages-support-index-js": () => import("/opt/build/repo/src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-investment-js": () => import("/opt/build/repo/src/pages/support/investment.js" /* webpackChunkName: "component---src-pages-support-investment-js" */),
  "component---src-pages-support-property-js": () => import("/opt/build/repo/src/pages/support/property.js" /* webpackChunkName: "component---src-pages-support-property-js" */)
}

