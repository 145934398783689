import React from "react";
import connect from "./connect";
import useSheet from "react-jss";
import { COLOR_BRAND_RED } from "../../colors";
import { formatNumber } from "accounting";

const styles = {
	labelCartCount: {
		position: "absolute !important",
		textAlign: "center !important",
		left: "50% !important",
		top: "50% !important",
		padding: "0 2px !important",
		lineHeight: "12px !important",
		transform: "translateX(-50%) translateY(-50%) !important",
		height: "15px !important",
		borderRadius: "4px !important",
		background: `${COLOR_BRAND_RED} !important`,
		fontWeight: "bold !important",
		fontSize: "9px !important",
		fontStyle: "normal !important",
		"&:empty": {
			display: "none !important"
		},
		color: "#fff !important"
	}
};

const Root = ({ count, classes }) => {
	if (!count || !count.data) {
		return null;
	}

	return <i className={classes.labelCartCount}>{formatNumber(count.data)}</i>;
};

export default connect(useSheet(styles)(Root));
