import { partition, sumBy } from "lodash";
import { useMemo } from "react";

// return auction stats shaped for view consumption
const present = auctions => {
	const auctionsAsArray = getAuctionsAsArray(auctions);

	const [canceled, forSale] = partition(
		auctionsAsArray,
		({ status }) => status === "Canceled" || status === "Cancelled"
	);

	const [saved, notSaved] = partition(
		forSale,
		({ status }) => status === "Saved"
	);

	const [winning, losing] = partition(
		notSaved,
		({ status }) => status === "Winning" // lump rejected & losing together
	);

	return {
		winningCount: winning.length,
		losingCount: losing.length,
		savedCount: saved.length,
		canceledCount: canceled.length,
		winningSum: sumAuctions(winning),
		losingSum: sumAuctions(losing),
		savedSum: sumAuctions(saved),
		canceledSum: sumAuctions(canceled)
	};
};

export const usePresenter = auctions =>
	useMemo(() => present(auctions), [auctions]);

const sumAuctions = auctions => sumBy(auctions, a => a.price * 100) / 100;

// take auction state keyed on lotNumber & convert to array
const getAuctionsAsArray = auctions =>
	Object.keys(auctions).map(lotNumber => {
		const auction = auctions[lotNumber];
		return {
			lotNumber,
			...auction
		};
	});

export default present;
