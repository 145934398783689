import { useCallback } from "react";
import { formatDisplayValue } from "../../normalize-percentages";

const useSavedBidInputEvents = ({ amount, setAmount, isMoney }) => {
	const handleChange = useHandleChange(setAmount);
	const handleBlur = useHandleBlur({ amount, setAmount, isMoney });

	return {
		handleChange,
		handleBlur
	};
};

const useHandleChange = setAmount =>
	useCallback(
		evt => {
			setAmount(Number(evt.target.value));
		},
		[setAmount]
	);

const useHandleBlur = ({ amount, setAmount, isMoney }) =>
	useCallback(() => {
		setAmount(formatDisplayValue(amount, isMoney));
	}, [amount, isMoney, setAmount]);

export default useSavedBidInputEvents;
