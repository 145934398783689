import React from "react";
import { PropTypes as t } from "prop-types";
import ColorizedOutlineButton from "../../colorized-outline-button";
import { COLOR_BIDDER } from "../../colors";
import ButtonContents from "./button-contents";

const PlaceBidButton = props => {
	const isBidding = props.isSubmitting || props.isProcessing;

	return (
		<ColorizedOutlineButton
			size="large"
			fullWidth
			disable={isBidding}
			color={COLOR_BIDDER}
			type="submit"
		>
			<ButtonContents verbose {...props} />
		</ColorizedOutlineButton>
	);
};

PlaceBidButton.propTypes = {
	amount: t.number.isRequired,
	isSubmitting: t.bool.isRequired,
	isProcessing: t.bool.isRequired
};

export default PlaceBidButton;
