import React from "react";
import ReactHighlightWords from "react-highlight-words";
import tc from "tinycolor2";
import { deburr } from "lodash";
import useSheet from "react-jss";
import { bootstrap } from "toetag";

const highlightBg = tc("#fd4")
	.setAlpha(0.75)
	.toString();

const highlighterStyles = {
	wrapper: {
		"&>span": {
			position: "relative",
			zIndex: 1
		}
	},
	match: {
		position: "relative",
		zIndex: 0,
		padding: [1, 2],
		margin: [-1, -2],
		background: highlightBg,
		borderRadius: bootstrap.borderRadiusSmall,
		boxShadow: {
			x: 1,
			y: 1,
			blur: 0,
			color: bootstrap.labelWarningBg
		}
	}
};

const Highlighter = ({ classes, ...props }) => (
	<ReactHighlightWords
		className={classes.wrapper}
		highlightClassName={classes.match}
		sanitize={deburr}
		{...props}
	/>
);

export default useSheet(highlighterStyles)(Highlighter);
