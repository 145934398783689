import { useCallback } from "react";
import { bootstrap } from "toetag";

const useCheckWindowCollapsed = setWindowCollapsed => {
	return useCallback(() => {
		const collapsed = window.matchMedia(
			`(max-width: ${bootstrap.gridFloatBreakpointMax})`
		).matches;
		setWindowCollapsed(collapsed);
	}, [setWindowCollapsed]);
};

export default useCheckWindowCollapsed;
