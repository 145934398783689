import React, { createContext, useContext } from "react";

import useChannel from "./hook";

const ChannelContext = createContext();

export const Provider = ({ children, problemDelay }) => {
	const result = useChannel(problemDelay);

	return (
		<ChannelContext.Provider value={result}>{children}</ChannelContext.Provider>
	);
};

export const useSocketSend = () => {
	const { send } = useContext(ChannelContext);
	return send;
};

export const useMessageHandler = onMsg => {
	const { useMessageHandler } = useContext(ChannelContext);
	useMessageHandler(onMsg);
};

export const useIsProblem = () => {
	const { isProblem } = useContext(ChannelContext);
	return isProblem;
};
