import React, { useEffect } from "react";
import useSheet from "react-jss";
import { Navbar, Nav, Grid, Row } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Headroom from "react-headroom";
import { partition, get } from "lodash";
import { Link } from "gatsby";
import NavOverlay from "./nav-overlay";
import links from "./links";
import DesktopMenu from "./desktop-menu";
import MobileMenu from "./mobile-menu";
import logo from "./logo.svg";
import styles from "./styles";
import UserHud from "../../user-hud";
import { useUserHudVisibility } from "../../user-hud/visibility";

const timeout = { enter: 1000, exit: 350 };

const NavbarRoot = ({
	setOpenMenu,
	handleSetOpenMenu,
	hideMenuTrue,
	debounceHideMenu,
	handleMouseEnter,
	navbarRef,
	navAreaRef,
	navToggleRef,
	measureRef,
	contentRect = {},
	navbarHeight,
	setNavbarHeight,
	...props
}) => {
	const { classes } = props;
	const menuLinks = links(props.user);
	const { openMenu } = props;
	const { isUserHudVisible } = useUserHudVisibility();

	const height = get(contentRect, "bounds.height", 0);
	useEffect(() => {
		setNavbarHeight(height);
	}, [height, setNavbarHeight]);

	const navOpacity =
		!props.openMenu && props.mapView ? { opacity: 0.75 } : { opacity: 1 };

	const [leftLinks, rightLinks] = partition(
		menuLinks,
		({ rightLink }) => !rightLink
	);
	const topLinks = !props.windowCollapsed ? (
		<DesktopMenu
			handleSetOpenMenu={handleSetOpenMenu}
			shoppingCart={classes.shoppingCart}
			selected={openMenu ? openMenu.text : false}
			user={props.user}
			{...{ classes, leftLinks, rightLinks, menuLinks }}
		/>
	) : (
		<MobileMenu
			tabItems={menuLinks}
			shoppingCart={classes.shoppingCart}
			user={props.user}
		/>
	);

	const dropDownLinks =
		openMenu !== null ? (
			<CSSTransition
				timeout={timeout}
				classNames={{
					enter: classes.navOverlayEnter,
					enterActive: classes.navOverlayEnterActive,
					exit: classes.navOverlayExit,
					exitActive: classes.navOverlayExitActive
				}}
			>
				<NavOverlay
					setOpenMenu={setOpenMenu}
					tabItems={openMenu.links}
					search={openMenu.search}
					mapView={props.mapView}
					user={props.user}
				/>
			</CSSTransition>
		) : null;

	const logoLink = (
		<Link to="/">
			<img src={logo} style={{ maxHeight: "51px", maxWidth: "100%" }} />
		</Link>
	);

	return (
		<Headroom
			onUnpin={hideMenuTrue}
			className={classes.headroom}
			downTolerance={20}
			upTolerance={10}
			pinStart={navbarHeight}
		>
			<div style={{ zIndex: -1 }} ref={measureRef}>
				<Navbar
					inverse
					className={classes.nav}
					onMouseLeave={debounceHideMenu}
					onMouseEnter={handleMouseEnter}
					ref={navbarRef}
					style={{ marginBottom: 0, ...navOpacity }}
				>
					{props.windowCollapsed ? (
						<div>
							<Grid
								fluid={props.mapView}
								style={{
									maxHeight: "80vh",
									overflowY: "auto",
									overflowX: "hidden"
								}}
							>
								<Navbar.Header>
									<div className="row" ref={navAreaRef}>
										<div className="col-xs-9">{logoLink}</div>
										<div className="col-xs-2 pull-right">
											<Navbar.Toggle ref={navToggleRef} />
										</div>
									</div>
								</Navbar.Header>
								<Navbar.Collapse>
									<Nav>
										<div className="col-md-8">{topLinks}</div>
									</Nav>
								</Navbar.Collapse>
							</Grid>
						</div>
					) : (
						<div>
							<div
								style={{
									zIndex: 2,
									position: "relative",
									background: "#222"
								}}
							>
								<Row>
									<div className="col-sm-3" style={{ height: "51px" }}>
										{logoLink}
									</div>
									<div className={classes.topLinks}>{topLinks}</div>
								</Row>
							</div>

							<div
								style={{
									position: "relative",
									overflow: "visible",
									height: 0
								}}
							>
								<TransitionGroup key={props.mapView}>
									{dropDownLinks}
								</TransitionGroup>
							</div>
						</div>
					)}
				</Navbar>
				{isUserHudVisible ? <UserHud isInNav /> : null}
			</div>
		</Headroom>
	);
};

export default useSheet(styles)(NavbarRoot);
