import React from "react";
import { Grid } from "react-bootstrap";

const Phrase = props => <span className="text-phrase" {...props} />;

const Footer = () => {
	return (
		<footer className="site-footer">
			<div className="site-footer__colophon__container text-center">
				<Grid>
					<p>
						<br />
						<Phrase>
							&copy; 2008 – {new Date().getFullYear()} CivicSource
							<sup>&reg;</sup>
						</Phrase>{" "}
						<span className="hidden-xs">|</span> <br className="visible-xs" />{" "}
						<Phrase>
							<a href="/privacy-policy">Privacy</a> |{" "}
							<a href="/terms-of-use">Terms</a> | The Property Auction Authority
							<sup>&reg;</sup>
						</Phrase>
					</p>
				</Grid>
			</div>
		</footer>
	);
};

export default Footer;
