import React from "react";
import useSheet from "react-jss";
import { defaultProps, compose } from "recompose";
import { lightBlue } from "@material-ui/core/colors";

import { InputBase, Button } from "@material-ui/core";

const ThemedInputGroup = ({
	inputChildren = <InputBase />,
	buttonChildren = "",
	buttonProps,
	labelChildren = "",
	classes
}) => {
	return (
		<div className={classes.root}>
			<div className={classes.inputHolder}>
				{inputChildren}
				<div className={classes.label}>{labelChildren}</div>
			</div>
			<Button
				variant="outlined"
				classes={{ root: classes.buttonRoot, outlined: classes.buttonRoot }}
				{...buttonProps}
			>
				{buttonChildren}
			</Button>
		</div>
	);
};

const firstChild = r => {
	return {
		borderTopLeftRadius: r,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: r,
		borderRight: 0
	};
};

const lastChild = r => {
	return {
		borderTopLeftRadius: 0,
		borderTopRightRadius: r,
		borderBottomRightRadius: r,
		borderBottomLeftRadius: 0
	};
};

const colorize = {
	color: "#7133FF !important",
	borderColor: "#7133FF !important",
	"& svg path:not([fill])": {
		fill: "currentColor"
	}
};

const styles = theme => {
	const u = theme.spacing.unit;
	const r = u / 2;
	return {
		root: {
			display: "flex",
			flex: "1 0 auto"
		},
		inputGroupItem: {
			"&:first-child": firstChild(r),
			"&:last-child": lastChild(r)
		},
		colorized: {
			color: props => [[props.color[300]], "!important"],
			borderColor: props => [[props.color[300]], "!important"]
		},
		outlined: {
			borderWidth: 1,
			borderStyle: "solid"
		},
		inputHolder: {
			flex: 1,
			padding: [u / 2, u * 2],
			borderRadius: r,
			composes: ["$inputGroupItem", "$outlined", "$colorized"],
			...colorize
		},
		adornment: {
			color: theme.palette.text.primary
		},
		label: {
			color: theme.palette.text.secondary,
			textAlign: "left"
		},
		buttonRoot: {
			composes: ["$inputGroupItem", "$outlined", "$colorized"],
			...colorize,
			"&:hover": {
				backgroundColor: props => [props.color[50], "!important"]
			}
		}
	};
};

const defaultColor = defaultProps({ color: lightBlue });
const styled = useSheet(styles);

export default compose(
	defaultColor,
	styled
)(ThemedInputGroup);
