import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "15px",
		height: "15px"
	}
});

export const LaIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 15 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="state_of_la_15px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M8.61592962,0.25 L0.25,0.25 L0.25,3.411972 C0.81276753,3.52621548 1.14766257,3.7824124 1.20637889,4.71384197 C1.13324323,4.87896603 1.01300068,5.06551768 0.843004149,5.27191589 C1.56488304,6.16265389 1.92945783,6.90685776 1.96756655,7.49586902 C2.00691478,8.10403827 1.72407772,9.10102292 1.09133914,10.4847436 L0.833861523,12.3942639 C1.44651664,12.0639712 2.05420089,11.9251876 2.65526424,11.9707829 C3.3467025,12.0232339 4.2503373,12.3445782 5.36327047,12.94746 L6.43356166,11.9324829 L8.61388621,12.7212528 C8.47421288,13.3595585 8.50885732,13.8584039 8.76735085,14.2014299 C8.97536872,14.4774737 9.32222947,14.6455803 9.7927136,14.727741 C10.2156949,14.0377161 10.6717005,13.7122398 11.1292884,13.6791687 C11.5278112,13.6503665 11.9538682,13.8408251 12.3933791,14.3012669 C12.4681391,14.1548258 12.5856703,13.9856061 12.8924454,13.6900919 C13.1534838,13.6077762 13.5239234,13.696846 14.0054009,14.0650071 L14.5143043,13.8569789 L13.6260836,13.1379264 C14.0492359,12.5674492 14.2328879,12.0149515 14.1533056,11.4762479 C14.0978762,11.1010392 13.8452949,10.6438494 13.6117173,10.2076647 C13.335047,9.69100816 13.0909176,9.19967997 13.0909176,8.87026306 C13.0909176,8.54637551 13.1848313,8.20822123 13.3797897,7.85777087 L7.65295819,7.85777087 L7.65295819,5.39841866 L9.0618924,3.68851212 C9.35040076,3.37211261 9.48633552,3.06345643 9.44761562,2.74524474 C9.40388046,2.3858161 9.12380647,1.55456059 8.61592962,0.25 Z"
				id="Path-22"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
		</g>
	</svg>
));
