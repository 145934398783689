import React from "react";
import BidWaiting from "./waiting";
import Rejection from "./rejection";
import { get } from "lodash";

const BidPlaceTooltip = ({
	isBidding,
	isProcessing,
	isRejected,
	tooltipPlacement,
	...props
}) => {
	const lotNumber = get(props, "auction.lotNumber");
	const rejectionReason = get(props, "currentBid.rejectionReason");

	const slowWarning = isBidding ? (
		<BidWaiting {...{ lotNumber, isProcessing, tooltipPlacement }} />
	) : null;

	const rejectedWarning = isRejected ? (
		<Rejection {...{ rejectionReason, tooltipPlacement }} />
	) : null;
	return rejectedWarning || slowWarning || null;
};

export default BidPlaceTooltip;
