import { bootstrap } from "toetag";
import tc from "tinycolor2";
import useSheet from "react-jss";

const animationName = "auction-going";

export default useSheet({
	[`@keyframes ${animationName}-flipInX`]: {
		"0%": {
			transform: "perspective(400px) rotate3d(1, 0, 0, 90deg)",
			animationTimingFunction: "ease-in",
			opacity: "0"
		},
		"40%": {
			transform: "perspective(400px) rotate3d(1, 0, 0, -20deg)",
			animationTimingFunction: "ease-in"
		},
		"60%": {
			transform: "perspective(400px) rotate3d(1, 0, 0, 10deg)",
			opacity: "1"
		},
		"80%": {
			transform: "perspective(400px) rotate3d(1, 0, 0, -5deg)"
		},
		"100%": {
			transform: "perspective(400px)"
		}
	},
	[`@keyframes ${animationName}-pulse`]: {
		"0%": {
			transform: "scale3d(1, 1, 1)"
		},
		"50%": {
			transform: "scale3d(1.05, 1.05, 1.05)"
		},
		"100%": {
			transform: "scale3d(1, 1, 1)"
		}
	},
	danger: {
		animation: `${animationName}-pulse 0.5s ease-in-out 3`,
		backgroundColor: bootstrap.brandDanger,
		borderColor: bootstrap.brandDanger,
		color: "#fff"
	},
	success: {
		backgroundColor: bootstrap.brandSuccess,
		borderColor: bootstrap.brandSuccess,
		color: "#fff"
	},
	info: {
		backgroundColor: bootstrap.brandInfo,
		borderColor: bootstrap.brandInfo,
		color: tc.mix(bootstrap.brandInfo, "#fff", 90).toString()
	},
	warning: {
		background: tc
			.mix(bootstrap.brandWarning, bootstrap.brandDanger, 20)
			.toString(),
		borderColor: tc
			.mix(bootstrap.brandWarning, bootstrap.brandDanger, 50)
			.toString(),
		color: tc.mix(bootstrap.brandWarning, "#fff", 90).toString()
	},
	base: {
		display: "inline-block",
		padding: `${bootstrap.paddingXsVertical} ${bootstrap.paddingXsHorizontal}`,
		borderRadius: bootstrap.borderRadiusSmall,
		border: "1px solid",
		fontStyle: "normal",
		transitionDuration: ".2s",
		transitionProperty: "color, background-color, border-color"
	},
	jumbo: {
		display: "block",
		padding: `${bootstrap.paddingSmallVertical} ${
			bootstrap.paddingSmallHorizontal
		}`,
		textAlign: "center",
		backfaceVisibility: "visible",
		animation: `${animationName}-flipInX 1s linear 1`
	},
	heading: {
		margin: `${bootstrap.paddingSmallVertical} 0`
	},
	soon: {},
	extended: { extend: "info" },
	advanced: { composes: "$info" },
	once: { extend: "warning" },
	twice: { extend: "danger" },
	winning: { extend: "success" },
	losing: { extend: "danger" },
	ended: { extend: "info" },
	sold: { extend: "info" },
	notSold: { extend: "info" },
	canceled: { extend: "info" },
	won: { extend: "success" },
	lost: { extend: "danger" }
});
