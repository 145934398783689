import { useCallback } from "react";
import { findDOMNode } from "react-dom";

const useNavbarCollapsed = ({ _navbar, hideMenu }) =>
	useCallback(
		e => {
			/* eslint-disable react/no-find-dom-node */
			const container = findDOMNode(_navbar.current);
			const clickInside = container ? container.contains(e.target) : null;
			if (!clickInside) hideMenu();
		},
		[_navbar, hideMenu]
	);

export default useNavbarCollapsed;
