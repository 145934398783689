import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useInterval from "@use-it/interval";
import useSocket from "react-socket-hooks";
import { get } from "lodash";

import { getCurrentUser } from "@civicsource/users";

const SOCKET_URL = `${process.env.BIDDING_API_URL.replace(
	"http",
	"ws"
)}activity`;

const getSocketUrl = user => {
	const token = get(user, "token");
	return token ? `${SOCKET_URL}?auth=${encodeURIComponent(token)}` : SOCKET_URL;
};

const useChannel = (problemDelay = 20000) => {
	const [isProblem, setProblem] = useState(false);
	const [isOutstandingPing, setOutstandingPing] = useState(false);

	const keepAliveMaxDelay = problemDelay / 2.0;

	const messageHandler = useCallback(() => {
		// receiving any message resets timers for a problem
		setOutstandingPing(false);
		setProblem(false);
	}, []);

	const user = useSelector(getCurrentUser);

	const { send, readyState, useMessageHandler } = useSocket(
		getSocketUrl(user),
		{
			keepAlive: true,
			keepAliveMaxDelay
		}
	);

	useMessageHandler(messageHandler);

	const isOpen = global.WebSocket && readyState == WebSocket.OPEN;

	useEffect(() => {
		let t;

		if (!isOpen || isOutstandingPing) {
			t = setTimeout(() => {
				setProblem(true);
			}, problemDelay);
		}

		return () => {
			if (t) {
				clearTimeout(t);
			}
		};
	}, [isOpen, isOutstandingPing, problemDelay]);

	const ping = useCallback(() => {
		send({ type: "Ping", body: "yo" });
		setOutstandingPing(true);
	}, [send]);

	useInterval(ping, isOpen ? keepAliveMaxDelay : null);

	return { isProblem, useMessageHandler, send };
};

export default useChannel;
