import bidding, * as fromBidding from "./bidding";
import bidHistory, * as fromBidHistory from "./bid-history";
import user, * as fromUser from "./user";

import { combineReducers } from "redux-seamless-immutable";
import { createSelector } from "reselect";

import { registerReducer } from "redux-rsi";

import { getBid } from "../../bid/reducer";

const TREE_ROOT = "bidding/auction";

registerReducer(
	TREE_ROOT,
	combineReducers({
		bidding,
		bidHistory,
		user
	})
);

const getAuctionBiddingInfoWithoutBids = createSelector(
	(state, lotNumber) =>
		fromBidding.getBiddingInfo(state[TREE_ROOT].bidding, lotNumber),
	(state, lotNumber) =>
		fromBidHistory.getBidHistory(state[TREE_ROOT].bidHistory, lotNumber),
	(auction, bidHistory) => auction.set("bids", bidHistory)
);

export const getAuctionBiddingInfo = createSelector(
	getAuctionBiddingInfoWithoutBids,
	state => state,
	(auction, state) => {
		let bids = auction.bids
			.set("items", auction.bids.ids.map(getBid.bind(this, state)))
			.without("ids");

		return auction
			.set("bids", bids)
			.set("winningBid", getBid(state, auction.winningBidId))
			.set("userCurrentBid", getBid(state, auction.userCurrentBidId))
			.without("winningBidId")
			.without("userCurrentBidId");
	}
);

export const getAuctionsForUser = createSelector(
	(state, username) =>
		fromUser.getLotNumbersForUser(state[TREE_ROOT].user, username),
	state => state,
	(result, state) =>
		result
			.set(
				"auctions",
				result.lotNumbers.map(lotNum => getAuctionBiddingInfo(state, lotNum))
			)
			.without("lotNumbers")
);
