import React from "react";
import { InputBase } from "@material-ui/core";
import getAdornmentProps from "../place/adornment";
import { bidInputPattern } from "../amount/format-bid";

const BidSaveInput = ({
	amount,
	startingBid,
	lotNumber,
	handleChange,
	handleBlur,
	isSaving,
	isMoney
}) => {
	const htmlId = `${lotNumber}-saved-bid-input`;
	const inputProps = {
		type: "number",
		min: isMoney ? startingBid : 1,
		max: isMoney ? undefined : 100,
		step: isMoney ? 0.01 : 1,
		pattern: bidInputPattern(isMoney)
	};

	// don't initialize input with value if amount is null or undefined
	if (amount != null) {
		return (
			<InputBase
				style={{ display: "flex" }}
				{...getAdornmentProps(isMoney)}
				disabled={isSaving}
				aria-label="Saved bid amount"
				id={htmlId}
				value={amount}
				onChange={handleChange}
				onBlur={handleBlur}
				inputProps={inputProps}
			/>
		);
	}

	return null;
};

export default BidSaveInput;
