import { useEffect } from "react";
import useSaveSavedBid from "./save";

const useDeleteSavedBid = ({ setAmount, startingBid, isMoney, ...req }) => {
	const {
		isSaving: isDeleting,
		isSaved: isDeleted,
		handleSave: handleDelete
	} = useHandleDelete({ setAmount, isMoney, ...req });

	useAfterDelete({ isDeleted, setAmount, startingBid, isMoney });

	return { isDeleting, isDeleted, handleDelete };
};

const useHandleDelete = ({ setAmount, isMoney, ...req }) => {
	return useSaveSavedBid({
		amount: null,
		isMoney,
		setAmount,
		...req
	});
};

const useAfterDelete = ({ isDeleted, setAmount, startingBid, isMoney }) => {
	useEffect(() => {
		if (isDeleted) {
			setAmount(isMoney ? startingBid : 100);
		}
	}, [isDeleted, isMoney, setAmount, startingBid]);
};

export default useDeleteSavedBid;
