import React, { useState, createContext, useContext } from "react";

const UserHudVisibilityContext = createContext();

export const useUserHudVisibility = () => {
	return useContext(UserHudVisibilityContext) || {};
};

export const UserHudVisibilityProvider = ({ children }) => {
	const [isUserHudVisible, setIsUserHudVisible] = useState(true);
	return (
		<UserHudVisibilityContext.Provider
			value={{ isUserHudVisible, setIsUserHudVisible }}
		>
			{children}
		</UserHudVisibilityContext.Provider>
	);
};
