import React, { useState, useCallback } from "react";

import BulkBidButton from "./button";
import BulkBidConfirmation from "./confirmation";
import BulkBidResults from "./results";

const BulkBidder = ({ hasSavedBids }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const handleOpenDialog = useCallback(() => setIsDialogOpen(true), [
		setIsDialogOpen
	]);
	const handleCloseDialog = useCallback(() => setIsDialogOpen(false), [
		setIsDialogOpen
	]);

	return (
		<>
			{hasSavedBids ? (
				<BulkBidButton
					handleOpenDialog={handleOpenDialog}
					hasSavedBids={hasSavedBids}
				/>
			) : null}
			<BulkBidConfirmation
				isDialogOpen={isDialogOpen}
				handleCloseDialog={handleCloseDialog}
				handleOpenDialog={handleOpenDialog}
			/>
			<BulkBidResults />
		</>
	);
};

export default BulkBidder;
