import React from "react";
import BidAmount from "../amount";
import UserInfo from "./user";
import BidCount from "./../count";
import BidHistoryModal from "./../history/modal";
import cx from "classnames";

import statify from "./state-container";

const AmountAndCount = ({
	auction,
	horizontal,
	onShowModal,
	amountPlaceholder,
	countPlaceholder,
	showLoading
}) => {
	const theBidCount = (
		<a className="small" href="#" onClick={onShowModal}>
			<BidCount
				showLoading={showLoading}
				customPlaceholder={countPlaceholder}
				auction={auction}
				showText
			/>
		</a>
	);
	const theBidAmount = (
		<BidAmount
			showLoading={showLoading}
			customPlaceholder={amountPlaceholder}
			auction={auction}
		/>
	);
	if (horizontal) {
		return (
			<div>
				{theBidCount} {theBidAmount}
			</div>
		);
	}

	return (
		<div>
			{theBidAmount}
			<br />
			{theBidCount}
		</div>
	);
};

const BidSummary = ({
	auction,
	showModal,
	onHideModal,
	amountPlaceholder,
	countPlaceholder,
	showLoading,
	...props
}) => {
	if (!auction) {
		return null;
	}

	const isLoaded = auction.isBiddingInfoLoaded;

	const classNames = cx({
		"bid-summary": true,
		"bid-summary--none": isLoaded && !auction.userCurrentBid,
		"bid-summary--winning text-success":
			isLoaded && !!auction.winningBid && auction.winningBid.isYourBid,
		"bid-summary--losing text-danger":
			isLoaded &&
			auction.userCurrentBid &&
			!!auction.winningBid &&
			!auction.winningBid.isYourBid
	});

	const user =
		auction.bidCount > 0 ? (
			<div className="small">
				<UserInfo auction={auction} />
			</div>
		) : null;

	return (
		<div className={classNames}>
			<AmountAndCount
				{...{ amountPlaceholder, countPlaceholder, showLoading }}
				auction={auction}
				{...props}
			/>
			{user}
			<BidHistoryModal
				show={showModal}
				onHide={onHideModal}
				auction={auction}
			/>
		</div>
	);
};

export default statify(BidSummary);
