import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "15px",
		height: "15px"
	}
});

export const MsIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
		width="210mm"
		height="297mm"
		viewBox="0 0 210 297"
		version="1.1"
	>
		<g id="state_of_ms_15px" stroke="none" strokeWidth="10" fill="none">
			<path
				id="path7041"
				stroke="#7133FF"
				strokeWidth="10"
				d="m 29.860118,15.119047 150.434522,-9.071428 8.31548,193.145831 16.63095,85.80061 -68.03571,7.55951 L 133.80357,260.4256 7.1815475,258.91369 21.922619,196.92559 5.2916666,124.73214 27.970239,68.035714 Z"
			/>
		</g>
	</svg>
));
