import React from "react";
import useSheet from "react-jss";
import { SUPPLEMENTARY_PURPLE } from "../../colors";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: ".8em",
		height: ".8em",
		marginRight: ".2em",
		fill: SUPPLEMENTARY_PURPLE
	}
});

export const ClearFiltersIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 15 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="clear_back_15px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M6.11995084,0.257263184 L14.4569702,0.257263184 C14.5950414,0.257263184 14.7069702,0.369191996 14.7069702,0.507263184 L14.7069702,14.5172119 C14.7069702,14.6552831 14.5950414,14.7672119 14.4569702,14.7672119 L6.12099463,14.7672119 C6.04457155,14.7672119 5.97234618,14.7322564 5.92493049,14.6723211 L0.344146946,7.6179894 C0.271839491,7.52658991 0.272277389,7.39735337 0.345202563,7.30644598 L5.92494232,0.350828926 C5.97239127,0.291679795 6.04412193,0.257263184 6.11995084,0.257263184 Z"
				id="Rectangle"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
			<line
				x1="6"
				y1="4.74410782"
				x2="12"
				y2="10.7441078"
				id="Path-19"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<line
				x1="6"
				y1="4.74410782"
				x2="12"
				y2="10.7441078"
				id="Path-19-Copy"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="translate(9.000000, 7.744108) scale(-1, 1) translate(-9.000000, -7.744108) "
			/>
		</g>
	</svg>
));
