import { LOC_VALUES } from "./filter-link";

const getFipsState = numStr => (numStr != null ? numStr.slice(0, 2) : null);

const locationQuery = ({ query, name, politicalSubDivision, value }) => {
	// Remove location filters beneath the current in the hierarchy, add those above
	if (name === LOC_VALUES.CITY) {
		query = query.addSearch(LOC_VALUES.STATE, getFipsState(value));
		if (politicalSubDivision) {
			query = query.addSearch(
				LOC_VALUES.POLITICAL_SUB_DIVISION,
				politicalSubDivision
			);
		}
	}
	if (name === LOC_VALUES.POLITICAL_SUB_DIVISION) {
		query = query
			.addSearch(LOC_VALUES.STATE, getFipsState(value))
			.removeSearch(LOC_VALUES.CITY);
	}
	if (name === LOC_VALUES.STATE) {
		query = query
			.removeSearch(LOC_VALUES.POLITICAL_SUB_DIVISION)
			.removeSearch(LOC_VALUES.CITY);
	}

	return query;
};

export default locationQuery;
