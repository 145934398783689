import { applyMiddleware, compose } from "redux";
import { createStore as createReduxStore } from "redux-rsi";
import { composeWithDevTools } from "redux-devtools-extension";

import Immutable from "seamless-immutable";

import { reduxBatch } from "@manaflair/redux-batch";
import thunk from "redux-thunk";
import {
	userCookieHydration,
	userServerTokenHydration,
	hydrateUser
} from "@civicsource/users";

import rollbarUserTracking from "./rollbar-user-tracking";

import cookies from "browser-cookies";

const isServer = typeof window === "undefined";

export function createStore() {
	let middleware = [thunk];

	if (!isServer) {
		middleware = [
			...middleware,
			userCookieHydration(cookies, {
				cookieName: process.env.AUTH_COOKIE,
				tokenOnly: true
			}),
			userServerTokenHydration,
			rollbarUserTracking // keep this after other user-related middleware
		];
	}

	const store = createReduxStore(
		Immutable({}),
		composeWithDevTools(reduxBatch, applyMiddleware(...middleware), reduxBatch)
	);

	// log the user in based on available cookie
	store.dispatch(hydrateUser());

	return store;
}

export function createTestStore() {
	return createReduxStore(
		Immutable({}),
		compose(
			reduxBatch,
			applyMiddleware(thunk),
			reduxBatch
		)
	);
}
