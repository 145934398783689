import React, { useState } from "react";

import { Helmet } from "react-helmet";
import _Navbar from "./navbar";
import Footer from "./footer";

import IeWarning from "./ie-warning";
import Channel from "../channel";
import { withContentRect } from "react-measure";

import { UserHudVisibilityProvider } from "../user-hud/visibility";
import { BulkBidderInProgressProvider } from "../user-hud/bulk-bidder/progress";

const withBounds = withContentRect("bounds");
const Navbar = withBounds(_Navbar);

const Layout = ({
	children,
	rootFontSize = 16,
	titleTemplate = "%s - CivicSource.com",
	defaultTitle = "CivicSource.com – The Property Auction Authority®",
	...props
}) => {
	const [navbarHeight, setNavbarHeight] = useState(51);
	const [mapView, setMapView] = useState(false);

	// React Helmet has bugger-all documentation and I don't have a lot of time, so instead of
	// reading the source to do this right, I'm going to hope FB/Twitter/etc can infer some stuff.
	const meta = [
		{
			name: "description",
			content: "Purchase property for $0 plus closing costs."
		},
		{
			name: "google-site-verification",
			content: "TA02YkZ5rVQI9k0EewRM38RbhOvptEXivCyA0-ijb_I"
		},
		// Don't hose SEO just for vanity's sake
		// { property: "og:title", content: "The Property Auction Authority®" },
		{
			property: "og:description",
			content: "Purchase property for $0 plus closing costs."
		},
		{
			property: "og:site_name",
			content: "CivicSource.com – The Property Auction Authority®"
		},
		// Assume client URL is canonical; don't override og:url
		{ property: "og:type", content: "website" },
		{ property: "og:image", content: "/social-media-banner.jpg" },
		{ property: "og:image:type", content: "image/jpeg" },
		{ property: "og:image:width", content: "1200" },
		{ property: "og:image:height", content: "628" },
		{
			property: "og:image:alt",
			content: "CivicSource logo above a house with trees in its yard"
		},
		// Twitter seems unable to infer title from the <title> element
		{
			property: "twitter:title",
			content: "The Property Auction Authority®"
		}
	];
	return (
		<UserHudVisibilityProvider>
			<BulkBidderInProgressProvider>
				<Helmet {...{ defaultTitle, titleTemplate, meta }}>
					{linkEls.map(l => (
						<link key={l.href} {...l} />
					))}
					<style>{`
				html {
					font-size: ${rootFontSize}px !important;
				}
			`}</style>
				</Helmet>
				<Helmet>
					<script type="application/ld+json">
						{`{ 
							"@context": "https://schema.org",
							"@type": "Organization",
							"name": "CivicSource",
							"url": "https://www.civicsource.com/",
							"logo": "https://i.ibb.co/kSt2xCq/cs-logo.png",
							"contactPoint": {
							"@type": "ContactPoint",
							"telephone": "(833) 692-4842",
							"contactType": "customer service",
							"availableLanguage": "en"
						 	 },
						  	"sameAs": [
								"https://www.facebook.com/CivicSource/",
								"https://www.linkedin.com/company/civicsource/",
								"https://twitter.com/civic_source"
						  	]
						 }`}
					</script>
				</Helmet>
				<IeWarning />
				<Channel />

				<div className="site-header-container" id="AuctioneerHeader">
					<div id="app-navbar" style={mapView ? { height: 0 } : null}>
						<header className="site-header">
							<Navbar
								{...props}
								{...{ mapView, setMapView, navbarHeight, setNavbarHeight }}
							/>
						</header>
					</div>
				</div>

				<ContentFooterWrapper>
					<ContentWrapper navbarHeight={navbarHeight}>
						{children}
					</ContentWrapper>

					<div
						className="site-footer__container home-section__container"
						id="AuctioneerFooter"
					>
						<Footer {...props} />
					</div>
				</ContentFooterWrapper>
			</BulkBidderInProgressProvider>
		</UserHudVisibilityProvider>
	);
};

const linkEls = [
	{
		rel: "author",
		href: process.env.COMMIT_REF
			? `https://github.com/civicsource/auctioneer/commit/${
					process.env.COMMIT_REF
			  }`
			: "unknown",
		"data-name": "ui-version"
	},
	{
		rel: "shortcut icon",
		href: "/favicon.ico"
	},
	{
		rel: "stylesheet",
		href:
			"//fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Open+Sans:wght@300;400;600;800"
	}
];

const ContentFooterWrapper = ({ children }) => (
	<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
		{children}
	</div>
);

const ContentWrapper = ({ children, navbarHeight }) => (
	<div
		className="layout-content-wrapper"
		style={{
			flexGrow: 2,
			flexShrink: 0,
			flexBasis: "auto",
			transition: ".25s padding-top",
			paddingTop: navbarHeight
		}}
	>
		{children}
	</div>
);

export default Layout;
