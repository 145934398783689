import React from "react";
import useSheet from "react-jss";
import { MenuItem, NavDropdown, NavItem } from "react-bootstrap";
import { bootstrap } from "toetag";
import { robotoStack } from "../../typography";
import CheckoutCount from "../../checkout/badge";
import { AccountIcon } from "./icons";

const style = {
	dropdown: {
		...robotoStack,
		padding: "10px 0px 10px 0px",
		"&>a:focus, &>a:not(:hover)": {
			backgroundColor: "transparent !important"
		},
		"&>ul:focus": {
			backgroundColor: "#525252 !important"
		},
		"&>a": {
			color: "white",
			...robotoStack,
			fontSize: "18px",
			textDecoration: "none",
			"&:hover": {
				backgroundColor: "transparent !important"
			}
		},
		"&>ul>li": {
			backgroundColor: "#525252"
		},
		"&>ul>li>a": {
			color: "#e7e7e7 !important",
			whiteSpace: "normal !important",
			paddingBottom: `${bootstrap.navbarPaddingVertical} !important`
		}
	},
	header: {
		...robotoStack,
		padding: "10px 0px 10px 0px",
		"&>a": {
			color: "white",
			fontSize: "18px"
		}
	},
	subHeader: {
		float: "left",
		color: "#6eb5e5"
	},
	search: {
		padding: "10px 0px 10px 0px"
	},
	shoppingCart: {
		padding: "10x 0px 10px 0px",
		composes: "fa-2x app-shopping-cart-icon"
	},
	icon: {
		padding: "10px 0px 10px 0px"
	}
};

const MobileMenu = ({ tabItems, classes, shoppingCart, user }) => {
	const tabsBesidesSearch = tabItems.filter(t => !t.search);
	const searchTab = tabItems.find(t => t.search);
	const transFormedTabItems = [...tabsBesidesSearch, searchTab];

	if (!transFormedTabItems) return null;
	return (
		<div>
			{transFormedTabItems.map((link, idx) => {
				const cartLink =
					link.text === "Shopping Cart" ? (
						<span className="text-phrase">
							<span className="fa-stack">
								<i className={shoppingCart} />
								<CheckoutCount />
							</span>
						</span>
					) : null;

				return link.account && !user ? (
					<NavItem href={link.href} key={link.text} className={classes.icon}>
						<AccountIcon />
					</NavItem>
				) : link.links && !link.search ? (
					<NavDropdown
						title={link.text}
						id={idx}
						className={classes.dropdown}
						key={link.text}
					>
						{link.links
							.reduce((a, b) => {
								// flatten links
								if (
									(!user && b.text === "Bidding") ||
									(!user && b.text === "Purchases")
								) {
									return a;
								}
								return [...a, ...(b.links ? [[...b.text], ...b.links] : [b])];
							}, [])
							.map(subLink =>
								!subLink.href ? (
									<MenuItem header key={subLink} className={classes.subHeader}>
										{subLink}
									</MenuItem>
								) : (
									<MenuItem key={subLink.text} href={subLink.href}>
										{subLink.text}
									</MenuItem>
								)
							)}
					</NavDropdown>
				) : !link.search ? (
					!user && link.text === "Shopping Cart" ? null : (
						<NavItem
							href={link.href}
							key={link.text}
							className={link.text === "Shopping Cart" ? null : classes.header}
						>
							{!link.icon ? link.text : cartLink}
						</NavItem>
					)
				) : (
					link.links[0].text
				);
			})}
		</div>
	);
};

export default useSheet(style)(MobileMenu);
