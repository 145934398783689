import React from "react";
import cx from "classnames";
import AuctionInfo from "../info-block";
import useSheet from "react-jss";
import styles from "./index.style";
import BidPlace from "../bid/place";
import BidSummary from "../bid/summary";
import AuctionTime from "../auction/time";
import { Grid } from "@material-ui/core";
import { getCurrentUser } from "@civicsource/users";
import { useSelector } from "react-redux";
import useChannelListen from "../channel/listen";

const AuctionUserBidItem = ({ classes, auction }) => {
	useChannelListen(auction.lotNumber);

	const { userCurrentBid } = auction;

	const classNames = cx({
		[classes.item]: true,
		[classes.canceled]: userCurrentBid && userCurrentBid.isCanceled,
		"list-group-item": true
	});

	const user = useSelector(getCurrentUser);

	return (
		<div style={{ padding: "8px 16px" }} className={classNames}>
			<Grid
				container
				spacing={16}
				style={auction.isEnded ? { opacity: 0.75 } : {}}
			>
				<Grid item md={5}>
					<AuctionInfo
						placeholder={!auction.sale || !auction.address}
						{...auction}
					/>
				</Grid>
				<Grid item md={4}>
					<div className="text-right">
						<BidSummary horizontal auction={auction} />
						<BidPlace user={user} showPlaceholder={false} auction={auction} />
					</div>
				</Grid>
				<Grid item md={3}>
					<div className="text-right">
						<AuctionTime auction={auction} />
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default useSheet(styles)(AuctionUserBidItem);
