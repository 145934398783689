import React, { Component } from "react";

export default DecoratedComponent =>
	class BidSummary extends Component {
		constructor(props) {
			super(props);

			this.state = {
				showModal: false
			};
		}

		onShowModal = ev => {
			if (ev) {
				ev.preventDefault();
			}
			this.setState({
				showModal: true
			});
		};

		onHideModal = ev => {
			if (ev) {
				ev.preventDefault();
			}
			this.setState({
				showModal: false
			});
		};

		render() {
			return (
				<DecoratedComponent
					{...this.props}
					{...this.state}
					onShowModal={this.onShowModal}
					onHideModal={this.onHideModal}
				/>
			);
		}
	};
