import { useReducer } from "react";
import { USER_BIDDING_AUCTIONS_FETCH } from "./initial-dispatch";

const OP_TYPES = {
	BID_ACCEPTED: "BID_ACCEPTED",
	BID_REJECTED: "BID_REJECTED",
	AUCTION_STATUS: "AUCTION_STATUS",
	BID_SAVED: "BID_SAVED"
};

const shouldHandleSocketMessage = ({ lotNumber, actionType }) =>
	!!lotNumber && Object.values(OP_TYPES).includes(actionType);

const useUserHudReducer = () =>
	useReducer((state, { type: actionType, payload }) => {
		if (actionType === USER_BIDDING_AUCTIONS_FETCH) {
			const result = {};

			if (payload && payload.length) {
				payload.forEach(({ lotNumber, status, price }) => {
					result[lotNumber] = { status, price };
				});
			}

			return result;
		}

		if (
			!shouldHandleSocketMessage({
				lotNumber: payload.lotNumber,
				actionType
			})
		) {
			return state;
		}

		if (actionType === OP_TYPES.BID_REJECTED) {
			const { lotNumber, amount } = payload;
			const existingAuction = state[lotNumber];

			if (existingAuction && existingAuction.status != "Saved") {
				// don't update a winning/losing auction with a rejected status
				// only update saved auctions with rejected status
				return state;
			}

			const result = { ...state };

			result[lotNumber] = {
				...existingAuction,
				price: amount,
				status: "Rejected"
			};
			return result;
		}

		const {
			lotNumber,
			winningBid,
			type: bidType,
			price,
			userCurrentBid,
			userSavedBid,
			rejectedBidCount,
			isCanceled
		} = payload;

		let result = { ...state };

		if (userCurrentBid && winningBid) {
			const { amount, isYourBid: isWinning } = winningBid;

			result[lotNumber] = {
				status: isCanceled ? "Canceled" : isWinning ? "Winning" : "Losing",
				price: bidType === "Percentage" ? price : amount
			};
		} else if (userSavedBid) {
			result[lotNumber] = {
				status: isCanceled ? "Canceled" : "Saved",
				price: bidType === "Percentage" ? price : userSavedBid
			};
		} else if (!rejectedBidCount) {
			delete result[lotNumber];
		}

		return result;
	}, {});

export default useUserHudReducer;
