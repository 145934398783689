import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import { Modal } from "react-bootstrap";

import BidButton from "./../button";
import Intent from "./intent";
import CoverYourAss from "./cover-ass";

class PlaceBidConfirmation extends Component {
	onSubmitModal = ev => {
		ev.preventDefault();
		this.props.onSubmitBid(ev);
		this.props.onHide(ev);
	};

	render() {
		const confirmBidButton = (
			<BidButton
				{...this.props}
				text="Confirm Bid"
				activeText="Confirming Bid"
			/>
		);

		const isPercentage =
			this.props.auction.bidType.toLowerCase() === "percentage";

		const winningProxyBidDescription = isPercentage
			? "highest winning bid on your behalf down to this percentage"
			: "lowest winning bid on your behalf up to this amount";

		return (
			<Modal
				show={this.props.showModal}
				onHide={this.props.onHide}
				bsSize="large"
			>
				<Modal.Header closeButton>
					<Modal.Title>Bid Confirmation</Modal.Title>
				</Modal.Header>
				<form data-noroute noValidate onSubmit={this.onSubmitModal}>
					<div className="modal-body text-center">
						<Intent {...this.props} />
						<p className="help-block">
							CivicSource will always place the {winningProxyBidDescription}.
						</p>
						<p>
							<strong>
								<span className="text-phrase">
									By placing a bid, you are entering into a binding contract
									with the seller
								</span>{" "}
								<span className="text-phrase">
									and are obligated to purchase the property, lien or
									certificate.
								</span>
							</strong>
							<br />
							<br />
							<strong>
								<span className="text-phrase">
									It is a crime to bid rig, price fix or collude at a public
									auction.
								</span>{" "}
								<span className="text-phrase">
									CivicSource monitors its auctions for signs of illegal,
									anti-competitive
								</span>{" "}
								<span className="text-phrase">
									behavior and will refer suspected violations to state and
									federal law enforcement officials.
								</span>
							</strong>
						</p>
						<br />
						<CoverYourAss {...this.props} />
					</div>
					<div className="modal-footer">
						<div className="text-center">{confirmBidButton}</div>
					</div>
				</form>
			</Modal>
		);
	}
}

PlaceBidConfirmation.propTypes = {
	auction: t.object.isRequired,
	csAuction: t.object,
	amount: t.number.isRequired,
	isSubmitting: t.bool.isRequired,
	isProcessing: t.bool.isRequired,
	onSubmitBid: t.func.isRequired,
	showModal: t.bool.isRequired
};

export default PlaceBidConfirmation;
