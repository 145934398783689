import useTimeout from "use-timeout";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchAuctionBiddingInfo } from "../auction/actions";
import { fetchBidHistory } from "../bid/history/actions";

const AuctionRefresher = ({
	lotNumber,
	delay,
	fetchAuctionBiddingInfo,
	fetchBidHistory
}) => {
	useTimeout(() => {
		fetchAuctionBiddingInfo(lotNumber);
		fetchBidHistory(lotNumber);
	}, delay);

	return null;
};

export default connect(
	null,
	dispatch =>
		bindActionCreators(
			{
				fetchAuctionBiddingInfo,
				fetchBidHistory
			},
			dispatch
		)
)(AuctionRefresher);
