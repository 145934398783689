import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import { Video } from "@civicsource/ui";
import useSheet from "react-jss";
import styles from "./index.style";

class ExtensionHelp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		};
	}

	showModal = ev => {
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: true
		});
	};

	hideModal = ev => {
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: false
		});
	};

	render() {
		const { classes } = this.props;

		const helpWhatsThis = <u className="help-link">What is this?</u>;

		const helpLink = (
			<a
				className={classes.helpLink}
				href="https://www.youtube.com/watch?v=XlRM5mAXSSc"
				onClick={this.showModal}
			>
				<span>End time may be extended</span> {helpWhatsThis}
			</a>
		);

		const footer = (
			<button className="btn btn-default" onClick={this.hideModal}>
				Close
			</button>
		);

		const extendHelp = (
			<Video.Player
				video={{
					title: "End Time May Be Extended",
					id: "XlRM5mAXSSc"
				}}
				smallTitle
				show={this.state.isModalOpen}
				onHide={this.hideModal}
				bsSize="large"
				footer={footer}
			/>
		);

		return (
			<div>
				{helpLink}
				{extendHelp}
			</div>
		);
	}
}

ExtensionHelp.propTypes = {
	endTime: t.string.isRequired
};

export default useSheet(styles)(ExtensionHelp);
