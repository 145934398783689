import { getActiveAuctionsForUser } from "../api";
import { getAuctionsForUser } from "../auction/reducer";
import { getCurrentUser } from "@civicsource/users";
import { createAjaxAction } from "redux-rsi";

export function fetchUserAuctions({ username, page, pageSize, type }) {
	return createAjaxAction(
		{
			type: "USER_AUCTIONS_FETCH",
			payload: username
		},
		getState => {
			const state = getState();
			const status = getAuctionsForUser(state, username);
			if (status.isLoading || status.isError) {
				return;
			}

			return getActiveAuctionsForUser(
				username,
				{ page, pageSize, type },
				getCurrentUser(state)
			);
		}
	);
}
