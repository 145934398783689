import React, { Component } from "react";

import {
	Card,
	CardContent,
	CardActions,
	TextField,
	Button,
	Typography
} from "@material-ui/core";

export default class LoginForm extends Component {
	state = { username: "", password: "" };

	handleTextChange = ev => {
		this.setState({ [ev.target.name]: ev.target.value });
	};

	handleLogin = ev => {
		ev.preventDefault();
		this.props.authenticate(this.state.username, this.state.password);
	};

	render() {
		const { isAuthenticating, isError } = this.props;
		const { username, password } = this.state;

		return (
			<Card>
				<form onSubmit={this.handleLogin}>
					<CardContent>
						<Typography variant="headline" component="h2">
							Sign in
						</Typography>
						<Typography variant="body1">
							with your CivicSource Account
						</Typography>
						<TextField
							error={isError}
							name="username"
							label="Email"
							margin="normal"
							value={username}
							fullWidth
							onChange={this.handleTextChange}
						/>
						<TextField
							error={isError}
							name="password"
							label="Password"
							type="password"
							margin="normal"
							value={password}
							helperText={isError ? "Invalid email/password" : null}
							fullWidth
							onChange={this.handleTextChange}
						/>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={isAuthenticating || !username || !password}
						>
							Sign In
						</Button>
						<Button disabled={isAuthenticating} href={"/login/forgotpassword"}>
							Forgot password?
						</Button>
					</CardActions>
				</form>
			</Card>
		);
	}
}
