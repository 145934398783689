import React from "react";
import useSheet from "react-jss";
import { formatNumber } from "accounting";
import { Link } from "gatsby";
import FilterIcon from "./filter-icon";
import locationQuery from "./location-query";
import Uri from "urijs";
import TextPlaceholder from "../../text-placeholder";
import { ClearFiltersIcon } from "./clear-icon";
import { CalendarIcon } from "./calendar-icon";
import StateIcon from "./stateIcons/state-icon";
import moment from "moment";

export const LOC_VALUES = {
	STATE: "state",
	POLITICAL_SUB_DIVISION: "politicalSubDivision",
	CITY: "city"
};

const queryIncludesPair = ({ url, name, value }) =>
	url.includes(`${name}=${value.replace(/ /g, "+")}`);

export function filterQuery({
	name,
	value,
	url,
	politicalSubDivision,
	clear = false,
	toggle = false
}) {
	if (clear) {
		return Uri(url)
			.search("")
			.toString();
	}

	let query =
		toggle && queryIncludesPair({ url, name, value })
			? // If it's already in the route and toggle behavior is enabled toggle it off
			  Uri(url).removeSearch(name)
			: // Otherwise set it
			  Uri(url).setSearch(name, value);

	query = locationQuery({ query, name, politicalSubDivision, value });

	return query.removeSearch("page").toString();
}

const FilterLink = ({
	placeholder,
	name,
	value,
	isoDatesValue,
	isSelected,
	display,
	count,
	url,
	classes,
	clear = false,
	containerComponent: Container = "li",
	standAlone,
	category
}) => {
	const whenCategory = category && category.startsWith("When");

	var taIcon;
	if (category === "Where") {
		taIcon = <StateIcon stateName={display} />;
	}

	const isWhatDisplay =
		category === "What" && (display === "Property" || display === "Investment");

	if (whenCategory && isoDatesValue) {
		const dates = isoDatesValue
			.trim()
			.split("\n")
			.map(d => moment(d.trim()));

		const start = dates[0];
		const end = dates[1];

		const isSameYearSale = start.year() === end.year();
		const isSameMonthSale = start.month() === end.month();
		const isOneDaySale =
			isSameYearSale && isSameMonthSale && start.date() === end.date();

		const dForm = "MMMM D";
		const formatTime = m => m.format(m.minute() > 0 ? "h:m a" : "h a");

		display = isOneDaySale
			? `${start.format(dForm)} (${formatTime(start)} - ${formatTime(end)})`
			: `${start.format(dForm)}, ${formatTime(start)} - ${end.format(
					dForm
			  )}, ${formatTime(end)}`;
	}

	return (
		<Container className={classes.container}>
			<Link
				className={isSelected ? classes.linkActive : classes.link}
				to={
					placeholder
						? ""
						: filterQuery({ name, url, value, toggle: true, clear })
				}
			>
				{(isSelected || clear) && standAlone ? (
					placeholder ? (
						<TextPlaceholder fullWidth />
					) : (
						<ClearFiltersIcon />
					)
				) : null}{" "}
				{(isSelected || clear) && !standAlone ? <span /> : null}{" "}
				<FilterIcon {...{ placeholder, name, value }} />{" "}
				{whenCategory ? <CalendarIcon style={{ marginTop: "4px" }} /> : null}{" "}
				{taIcon}{" "}
				{placeholder ? <TextPlaceholder likePhrase={display} /> : display}
			</Link>{" "}
			{count ? (
				<span
					className={classes.count}
					style={isWhatDisplay ? { marginTop: "8.5px" } : null}
				>
					{placeholder ? (
						<TextPlaceholder likePhrase={formatNumber(count)} />
					) : (
						formatNumber(count)
					)}
				</span>
			) : null}
		</Container>
	);
};

const styles = {
	container: {
		composes: "clearfix"
	},
	link: {
		composes: "pull-left",
		"&, &:hover, &:focus": {
			textDecoration: "none"
		},
		color: "#A4A1A1",
		margin: "3px 0"
	},
	linkActive: {
		composes: "$link",
		fontWeight: "bold"
	},
	count: {
		composes: ["text-muted", "pull-right"],
		margin: "3px 0",
		verticalAlign: "baseline"
	}
};

export default useSheet(styles)(FilterLink);
