import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import BidHistory from ".";
import connect from "./connect";

class BidHistoryModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			windowHeight: 0
		};
	}

	componentDidMount() {
		this.updateLayout();
		window.addEventListener("resize", this.updateLayout);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateLayout);
	}

	updateLayout = () => {
		this.setState({
			windowHeight: window.innerHeight
		});
	};

	render() {
		var windowHeight = +this.state.windowHeight;
		var padding = 180; // TODO: Get sum of chrome dimensions and use those instead of magic number
		var height = windowHeight > padding ? windowHeight - padding : "auto";
		var style = {
			height: height,
			overflowY: "auto"
		};

		return (
			<Modal
				show={this.props.show}
				className="bid-history__modal__container"
				onHide={this.props.onHide}
				bsSize="large"
			>
				<Modal.Header closeButton>Bid History</Modal.Header>
				<div className="modal-body bid-history__modal__body" style={style}>
					<BidHistory {...this.props} isInModal />
				</div>
				<div className="modal-footer bid-history__modal__footer">
					<button className="btn btn-default" onClick={this.props.onHide}>
						Close
					</button>
				</div>
			</Modal>
		);
	}
}

export default connect(BidHistoryModal);
