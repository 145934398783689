import { bootstrap } from "toetag";
import { get, capitalize } from "lodash";
import tc from "tinycolor2";

export const getTooltipBaseColor = color =>
	tc(color)
		.darken(8)
		.toString();

export const colorizeInner = color => ({
	backgroundColor: color ? getTooltipBaseColor(color) : bootstrap.tooltipBg
});

export const colorizeArrow = (color, direction) => {
	return {
		borderColor: "transparent",
		[`border${capitalize(direction)}Color`]: getTooltipBaseColor(color)
	};
};

export default (direction, severity) => {
	const baseColor = get(bootstrap, `brand${capitalize(severity)}`);

	return {
		large: {
			maxWidth: "none",
			width: "100%"
		},
		tooltipZ: {
			zIndex: bootstrap.zindexNavbar - 1
		},
		icon: {
			position: "absolute",
			left: `${1 / 3}em`
		},
		hasIcon: {
			paddingLeft: "3em"
		},
		inner: colorizeInner(baseColor),
		arrow: colorizeArrow(baseColor, direction)
	};
};
