import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "15px",
		height: "15px"
	}
});

export const TnIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		xmlns="http://www.w3.org/2000/svg"
		width="210mm"
		height="297mm"
		viewBox="0 0 310 225"
		version="1.1"
	>
		<g
			id="state_of_tn_15px"
			stroke="none"
			strokeWidth="10"
			fill="none"
			transform="translate(0,250) rotate(-90)"
		>
			<path
				d="M 152.22056,23.40256 75.242295,1.5140379 76.52836,217.19603 l 15.444773,1.79022 14.801737,12.53566 18.33884,6.08629 11.9085,15.75722 13.83634,41.05812 17.72015,0.53727 -1.46602,-216.063761 -15.04649,-0.972013 z"
				id="path7041"
				stroke="#7133FF"
				strokeWidth="10"
			/>
		</g>
	</svg>
));
