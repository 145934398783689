/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import useSheet from "react-jss";
import AuctionUserBidItem from "./auction-user-bid";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { get, sortBy, keyBy } from "lodash";
import { ListGroup } from "react-bootstrap";
import styles from "./index.style";
import {
	FirstPage,
	LastPage,
	KeyboardArrowLeft,
	KeyboardArrowRight
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getAuctionsForUser } from "./../auction/reducer";
import { fetchUserAuctions } from "./actions";
import { getCurrentUser } from "@civicsource/users";
import { formatNumber } from "accounting";
import useCivicSourceAuctionsInfo from "./use-civicsource-auctions-info";
import queryString from "query-string";
import { Link } from "@reach/router";

const AuctionList = props => {
	const { classes, location, isSessionInProgress, type } = props;

	const { username } = useSelector(getCurrentUser);

	const auctionResponse = useSelector(state =>
		getAuctionsForUser(state, username)
	);

	const { auctions, totalCount, isLoading } = auctionResponse || {};
	const dispatch = useDispatch();

	let { page = 1, pageSize = 10 } =
		location && location.search ? queryString.parse(location.search) : {};

	page = Number(page);
	pageSize = Number(pageSize);
	const totalPages = Math.ceil(totalCount / pageSize);

	useEffect(() => {
		dispatch(fetchUserAuctions({ username, page, pageSize, type }));
	}, [dispatch, username, page, pageSize, type]);

	const biddingAuctionsByLotNumber = useMemo(
		() => keyBy(auctions || [], "lotNumber"),
		[auctions]
	);

	const sortedAuctions = useMemo(
		() =>
			sortBy(auctions || [], a =>
				new Date(get(a, "schedule.endTime")).getTime()
			),
		[auctions]
	);

	const lotNumbers = sortedAuctions.map(a => a.lotNumber);

	const csAuctions = useCivicSourceAuctionsInfo(lotNumbers);

	const mergedAuctions = useMemo(
		() =>
			csAuctions && get(csAuctions, "[0]")
				? csAuctions.map(c => {
						return {
							...c,
							...biddingAuctionsByLotNumber[c.lotNumber]
						};
				  })
				: null,
		[biddingAuctionsByLotNumber, csAuctions]
	);

	// initialize the list we will be rendering as empty
	const [auctionResult, setAuctionResult] = useState([]);

	useEffect(() => {
		// only populate the rendered list if it's not gonna be thrashing,
		// but go ahead and do it once if it's empty

		if (!isSessionInProgress || !auctionResult.length) {
			const result = mergedAuctions || sortedAuctions;

			setAuctionResult(result);
		}
		if (!auctions.length) {
			setAuctionResult([]);
		}
	}, [
		auctions.length,
		auctionResult.length,
		isSessionInProgress,
		mergedAuctions,
		sortedAuctions,
		type
	]);

	if (auctionResult && auctionResult.length) {
		return (
			<div
				style={{
					opacity: isSessionInProgress ? 0.5 : 1,
					transition: ".2s opacity"
				}}
			>
				<ListGroup className={classes.items}>
					<div
						className={`${classes.item} list-group-item`}
						style={{ padding: "8px 16px" }}
					>
						<Grid container spacing={16}>
							<Grid item md={5}>
								Auction
							</Grid>
							<Grid item md={4}>
								<div className="text-right">Bid</div>
							</Grid>
							<Grid item md={3}>
								<div className="text-right">End Time</div>
							</Grid>
						</Grid>
					</div>
					{auctionResult.map(a => (
						<AuctionUserBidItem
							username={username}
							auction={a}
							key={a.lotNumber}
						/>
					))}
					<div
						style={{
							display: "flex",
							margin: "0 auto",
							alignItems: "center",
							justifyContent: "center"
						}}
					>
						<IconButton
							component={Link}
							disabled={page === 1}
							to={`${location.pathname}?page=1`}
						>
							<FirstPage />
						</IconButton>
						<IconButton
							component={Link}
							disabled={page === 1}
							to={`${location.pathname}?page=${Math.max(1, page - 1)}`}
						>
							<KeyboardArrowLeft />
						</IconButton>
						{formatNumber(page * pageSize - pageSize + 1)}-
						{formatNumber(Math.min(page * pageSize, totalCount))} of{" "}
						{formatNumber(totalCount)} {type} bids
						<IconButton
							component={Link}
							disabled={page === totalPages}
							to={`${location.pathname}?page=${Math.min(totalPages, page + 1)}`}
						>
							<KeyboardArrowRight />
						</IconButton>
						<IconButton
							component={Link}
							disabled={page === totalPages}
							to={`${location.pathname}?page=${totalPages}`}
						>
							<LastPage />
						</IconButton>
					</div>
				</ListGroup>
			</div>
		);
	}

	return (
		<Typography align="center">
			{isLoading ? <>Loading {type} bids</> : <>No {type} bids to display.</>}
		</Typography>
	);
};

export default useSheet(styles)(AuctionList);
