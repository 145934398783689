import React from "react";
import { PropTypes as t } from "prop-types";
import { WaitingIndicator } from "@civicsource/ui";
import { Button } from "react-bootstrap";
import Waypoint from "react-waypoint";

const ShowMoreBids = ({ lotNumber, bids, isLoadingMoreBids, onClick }) => {
	const buttonContents = isLoadingMoreBids ? (
		<WaitingIndicator showText message="loading more bids..." />
	) : (
		"Show more"
	);

	return (
		<Waypoint onEnter={handleClickShowMore}>
			<div>
				<Button onClick={handleClickShowMore} bsSize="sm" bsStyle="link" block>
					{buttonContents}
				</Button>
			</div>
		</Waypoint>
	);

	function handleClickShowMore() {
		const oldestBid = bids.items[bids.items.length - 1];
		onClick(lotNumber, oldestBid.id);
	}
};

ShowMoreBids.propTypes = {
	lotNumber: t.string.isRequired,
	bids: t.object.isRequired,
	isLoadingMoreBids: t.bool,
	onClick: t.func.isRequired
};

export default ShowMoreBids;
