import React, { useState } from "react";
import useTimeout from "use-timeout";

import customTooltip from "./tooltip.style";
import AuctionForceRefresher from "../../auction/refresher";

const DELAY = 5000;
const FORCE_REFRESH_DELAY = DELAY * 2;

const useWarning = delay => {
	const [showWarning, setWarning] = useState(false);
	useTimeout(() => setWarning(true), delay);
	return showWarning;
};

const BidWaiting = ({
	lotNumber,
	tooltipPlacement = "bottom",
	isProcessing
}) => {
	const showWarning = useWarning(DELAY);

	if (!showWarning) {
		return null;
	}

	const styles = customTooltip(tooltipPlacement, null);

	const submittingMessage = (
		<span>
			It's taking longer than expected to submit your bid.
			<br />
			Please check your connection, but
			<strong>don't leave or refresh the page</strong>
			or your bid may not be processed.
		</span>
	);

	const processingMessage = (
		<span>
			<strong>Your bid has been submitted</strong>, but it seems your connection
			to our servers is unreliable. If this message persists, please check your
			connection and/or try refreshing the page.
		</span>
	);

	return (
		<div
			className={`tooltip in ${tooltipPlacement}`}
			style={{ ...styles.tooltipZ }}
		>
			<div className={`tooltip-arrow ${styles.arrow}`} />
			<div
				className="tooltip-inner"
				style={{
					...styles.large,
					...styles.hasIcon,
					...styles.inner
				}}
			>
				{isProcessing ? processingMessage : submittingMessage}
				<AuctionForceRefresher
					lotNumber={lotNumber}
					delay={FORCE_REFRESH_DELAY}
				/>
			</div>
		</div>
	);
};

export default BidWaiting;
