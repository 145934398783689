import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		width: "2.5em",
		height: "2em",
		verticalAlign: "baseline"
	},
	accountHover: {
		"&:hover > g > g > g > path": {
			stroke: "#ADD6FF"
		},
		"&:hover > g > g > g > ellipse": {
			stroke: "#ADD6FF"
		},
		"&:hover > g > g > g > mask": {
			fill: "#ADD6FF"
		}
	},
	searchHover: {
		"&:hover > g > g": {
			stroke: "#ADD6FF"
		}
	}
});

export const AccountIcon = styled(({ classes }) => (
	<svg
		viewBox="0 0 13 18"
		className={[classes.icon, classes.accountHover].join(" ")}
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<rect id="path-1" x="0" y="11" width="19" height="6" />
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="1" transform="translate(-1350.000000, -24.000000)">
				<g id="user_icon" transform="translate(1348.000000, 25.000000)">
					<ellipse
						id="Oval-Copy"
						stroke="#cecece"
						fillRule="nonzero"
						cx="8.73529412"
						cy="5.71428571"
						rx="5.73529412"
						ry="5.71428571"
					/>
					<mask id="mask-2" fill="#cecece">
						<use xlinkHref="#path-1" />
					</mask>
					<g
						id="Mask"
						transform="translate(9.500000, 14.000000) scale(1, -1) translate(-9.500000, -14.000000) "
					/>
					<path
						d="M5.40072419,14.5 C4.64599666,14.5 4.00880206,15.0607629 3.91288666,15.8093708 L3.78476166,16.8093708 C3.77666158,16.8725909 3.77259919,16.9362631 3.77259919,17 C3.77259919,17.8284271 4.44417207,18.5 5.27259919,18.5 L12.7539006,18.5 C12.8118109,18.5 12.8696727,18.4966464 12.9271953,18.489956 C13.7500752,18.394248 14.3395646,17.6495853 14.2438566,16.8267053 L14.127548,15.8267053 C14.0395853,15.0704185 13.398977,14.5 12.637592,14.5 L5.40072419,14.5 Z"
						id="Rectangle"
						stroke="#cecece"
						mask="url(#mask-2)"
					/>
				</g>
			</g>
		</g>
	</svg>
));

export const SearchIcon = styled(({ classes }) => (
	<svg
		className={[classes.icon, classes.searchHover].join(" ")}
		viewBox="0 0 16 17"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Home-Page"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Home"
				transform="translate(-1256.000000, -24.000000)"
				stroke="#cecece"
				strokeWidth="0.75"
			>
				<g id="Top-Nav">
					<g id="Group-3">
						<g id="Menu">
							<g id="search_icon" transform="translate(1257.080000, 25.000000)">
								<path
									d="M9.69230769,10.6666667 C11.1127828,12.427298 12.5795249,14.2393687 14,16"
									id="Path-2"
									strokeLinecap="round"
								/>
								<ellipse
									id="Oval"
									fillRule="nonzero"
									cx="5.92307692"
									cy="5.86666667"
									rx="5.92307692"
									ry="5.86666667"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
));
