import React, { Component } from "react";
import AmountInput from "./input";
import ConfirmModal from "./confirmation";
import ButtonContents from "./button-contents";

import ThemedInputGroup from "../../themed-input-group";

export default class PlaceBidForm extends Component {
	state = {
		isModalOpen: false
	};

	isModalEnabled = () => {
		const { amount, isProcessing, isSubmitting, max, min } = this.props;
		var isBusy = isSubmitting || isProcessing;
		var isAmountOutOfRange = !amount || amount < min || amount > max;
		return !(isBusy || isAmountOutOfRange);
	};

	onBidIntent = ev => {
		ev.preventDefault();
		if (this.isModalEnabled()) {
			this.setState({
				isModalOpen: true
			});
		}
	};

	hideModal = ev => {
		// bs react modal's handleDocumentKeyup method doesn't pass event
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: false
		});
	};

	render() {
		const {
			amount,
			auction,
			isProcessing,
			isSubmitting,
			max,
			min,
			onAmountChanged
		} = this.props;
		const { isModalOpen } = this.state;
		var isBidding = isSubmitting || isProcessing;

		var confirmModal = isModalOpen ? (
			<ConfirmModal
				{...this.props}
				onHide={this.hideModal}
				showModal={isModalOpen}
			/>
		) : null;

		const isMoney = auction.bidType.toLowerCase() === "money";

		const buttonContents = <ButtonContents {...this.props} />;

		const amountInput = (
			<AmountInput
				onChange={onAmountChanged}
				isDisabled={isBidding}
				{...{ isMoney, auction, amount, min, max }}
			/>
		);

		return (
			<div className="place-bid-form">
				<form data-noroute noValidate onSubmit={this.onBidIntent}>
					<div style={{ display: "flex" }}>
						<ThemedInputGroup
							// labelChildren={isBidding ? "Bidding" : "Bid"}
							inputChildren={amountInput}
							buttonProps={{
								disabled: !this.isModalEnabled(),
								type: "submit"
							}}
							buttonChildren={buttonContents}
						/>
					</div>
				</form>
				{confirmModal}
			</div>
		);
	}
}
