import React from "react";
import cx from "classnames";

import Bidder from "../entries/bid/bidder";

import { HighlightOnChange, Time } from "@civicsource/ui";
import formatBid from "../../amount/format-bid";

const BidSummaryItem = ({ classes, auction, bid, getUserUrl }) => {
	var bidClasses = cx({
		[classes.item]: true,
		[classes.you]: bid.isYourBid,
		[classes.youPublic]: bid.isYourBid,
		[`success ${classes.youWinning}`]: bid.isWinning && bid.isYourBid,
		[`danger ${classes.youLosing}`]: !bid.isWinning && bid.isYourBid,
		success: bid.isWinning
	});

	return (
		<tr className={bidClasses}>
			<td>
				<Bidder bid={bid} getUserUrl={getUserUrl} />
			</td>
			<td className="text-right">
				<BidSummaryAmount
					{...bid}
					bidType={auction.bidType}
					classes={classes}
				/>
			</td>
			<td className="text-center">
				<Time value={bid.time} format="MM/DD/YYYY hh:mm:ss.SSS A" />
			</td>
		</tr>
	);
};

const BidSummaryAmount = ({
	proxyAmount,
	amount,
	isYourBid,
	bidType,
	classes
}) => {
	const formattedAmount = formatBid(proxyAmount || amount, bidType);

	const maxBid = proxyAmount ? (
		<small
			className={classes.maxBid}
			title={
				isYourBid
					? "Your maximum bid (only you can see this)"
					: "User's maximum bid"
			}
		>
			<i
				className={`fa fa-arrow-up ${classes.maxBidIcon}`}
				aria-hidden="true"
			/>{" "}
			{formatBid(amount, bidType)}
		</small>
	) : null;

	return (
		<HighlightOnChange subscribeTo={formattedAmount}>
			{formattedAmount} {maxBid}
		</HighlightOnChange>
	);
};

export default BidSummaryItem;
