import { getAuction as apiGetAuctionBiddingInfo } from "../api";

import { createAjaxAction } from "redux-rsi";

import { getAuctionBiddingInfo } from "./reducer";
import { getCurrentUser } from "@civicsource/users";
import { normalize } from "../normalize";

export function fetchAuctionBiddingInfo(lotNumber) {
	return createAjaxAction(
		{
			type: "AUCTION_BIDDING_INFO_FETCH",
			payload: lotNumber
		},
		getState => {
			const state = getState();

			const auction = getAuctionBiddingInfo(state, lotNumber);

			if (auction.isBiddingInfoLoading || auction.isBiddingInfoMissing) {
				return;
			}

			return apiGetAuctionBiddingInfo(
				normalize(lotNumber),
				getCurrentUser(state)
			);
		}
	);
}

export function auctionBiddingStatusChanged(msg) {
	return {
		type: "AUCTION_BIDDING_STATUS_CHANGED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}

export function auctionBidSaved(msg) {
	return {
		type: "AUCTION_BID_SAVED",
		payload: msg.body,
		meta: {
			source: "websocket"
		}
	};
}
