import React from "react";

const SignInButton = () => (
	<a
		className="btn btn-primary"
		href={`/login?ReturnUrl=${document.location.pathname}`}
	>
		Sign In to Place Bid
	</a>
);

export default SignInButton;
