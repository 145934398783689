import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		width: "1em",
		height: "0.85em",
		verticalAlign: "baseline",
		fill: "currentColor",
		stroke: "currentColor"
	}
});

export const Paddle = styled(({ classes }) => (
	<svg viewBox="0 0 32 32" className={classes.icon}>
		<path d="M17.7 21.5c0-1.6.6-2 0-2-1.1.4-2.5.3-3.4 0-.6 0 0 .1 0 2 0 2.8-1.7 5.5-1.7 7.1S14.1 32 16 32s3.4-1.6 3.4-3.4c0-1.8-1.7-4.4-1.7-7.1z" />
		<circle cx="16" cy="10" r="9" />
	</svg>
));

export const PaddleAlt = styled(({ classes }) => (
	<svg viewBox="0 0 32 32" className={classes.icon}>
		<path d="M17.7 21.5c0-1.6.6-2 0-2-1.1.4-2.5.3-3.4 0-.6 0 0 .1 0 2 0 2.8-1.7 5.5-1.7 7.1S14.1 32 16 32s3.4-1.6 3.4-3.4c0-1.8-1.7-4.4-1.7-7.1zM16 3c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-2c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
	</svg>
));

export const PaddleOutline = styled(({ classes }) => (
	<svg viewBox="0 0 32 32" className={classes.icon}>
		<path d="M16 2c4.4 0 8 3.6 8 8 0 3.7-2.7 7-6.3 7.8l-.7.2-.1.7c0 .2 0 .5-.1.7-.1.7-.1 1.5-.1 2.1 0 1.6.6 3.2 1 4.6.3 1 .7 1.9.7 2.5 0 1.3-1.1 2.4-2.4 2.4-1.3 0-2.4-1.3-2.4-2.4 0-.5.3-1.4.6-2.3.5-1.4 1.1-3.1 1.1-4.8 0-.7-.1-1.5-.1-2.3v-.5L15 18l-.7-.2C10.7 17 8 13.7 8 10c0-4.4 3.6-8 8-8m0-1c-5 0-9 4-9 9 0 4.3 3 7.9 7.1 8.8.1.9.2 1.9.2 2.7 0 2.8-1.7 5.5-1.7 7.1S14.1 32 16 32s3.4-1.6 3.4-3.4c0-1.8-1.7-4.4-1.7-7.1 0-.7.1-1.8.2-2.7C22 17.9 25 14.3 25 10c0-5-4-9-9-9z" />
	</svg>
));
