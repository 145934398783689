import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { submitBid } from "../actions";
import { getCurrentBid } from "../reducer";
import { getAuthStatus } from "@civicsource/users";

export default function connectCurrentBid(component) {
	return connect(
		(state, { auction, lotNumber }) => {
			const lotNum = auction ? auction.lotNumber : lotNumber;

			let result = {
				isAuthenticated: getAuthStatus(state).isAuthenticated
			};

			if (lotNum) {
				result.currentBid = getCurrentBid(state, lotNum);
			}

			return result;
		},
		dispatch =>
			bindActionCreators(
				{
					onSubmitBid: submitBid
				},
				dispatch
			)
	)(component);
}
