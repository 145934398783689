import React from "react";
import useSheet from "react-jss";

import chromeImg from "./chrome.png";
import edgeImg from "./edge.png";
import firefoxImg from "./firefox.png";

const IeWarning = ({
	classes: { container, text, browser, browserImage, edge, browserImageText }
}) => (
	<>
		<div id="ieWarning" className="hidden" hidden>
			<div className={container}>
				<div className={text}>
					<p>
						<strong>Please upgrade your browser.</strong>
						<br />
						We built CivicSource<sup>&reg;</sup> using the latest technology
						that is unfortunately not supported by your browser.
						<br />
						For an improved experience, please download one of the following:
					</p>
					<div>
						<div className={browser}>
							<a href="https://www.google.com/chrome/">
								<img className={browserImage} src={chromeImg} />
								<p className={browserImageText}>Google Chrome</p>
							</a>
						</div>
						<div className={browser}>
							<a href="https://www.mozilla.org/en-US/firefox/new/">
								<img className={browserImage} src={firefoxImg} />
								<p className={browserImageText}>Mozilla Firefox</p>
							</a>
						</div>
						<div className={browser}>
							<a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
								<img className={edge} src={edgeImg} />
								<p className={browserImageText}>Microsoft Edge</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<script
			dangerouslySetInnerHTML={{
				__html: `
					(function ieWarningCheck() {
						var ua = window.navigator.userAgent;
						var msie = ua.indexOf("MSIE ");
						if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11./)) {
							var warningContent = document.getElementById("ieWarning");
					
							if (warningContent && warningContent.classList) {
								warningContent.classList.remove("hidden");
								warningContent.removeAttribute("hidden");
							}
						}
					})();		
				`
			}}
		/>
	</>
);

const styles = {
	container: {
		backgroundColor: "white",
		padding: 20
	},
	text: {
		textAlign: "center",
		margin: 0,
		fontFamily: "sans-serif",
		"& > p": {
			margin: [0, 0, 20, 0],
			fontSize: 20
		}
	},
	browser: {
		position: "relative",
		display: "inline-block",
		width: 100,
		"& a": {
			textDecoration: "none",
			color: "#000",
			"&:hover, &:focus": { opacity: 0.5 }
		}
	},

	browserImage: {
		height: 50
	},
	edge: {
		marginBottom: 3,
		height: 42
	},
	browserImageText: {
		fontSize: 12,
		textDecoration: "none",
		margin: 0
	}
};

export default useSheet(styles)(IeWarning);
