import React from "react";
import { useSelector } from "react-redux";
import useUserHud from "./hook";
import { getCurrentUser } from "@civicsource/users";
import { Paper } from "@material-ui/core";
import useSheet from "react-jss";
import UserHudRoot from "./root";
import BulkBiddingProgress from "./bulk-bidder/progress";

export const TEST_ID = "userhud";

const UserHud = ({ isInNav, activeTab, classes }) => {
	const user = useSelector(getCurrentUser);

	const stats = useUserHud(user);

	const showHud = !!(stats
		? stats.winningCount || stats.losingCount || stats.savedCount || !isInNav
		: false);

	return (
		<>
			{showHud ? (
				<div data-testid={TEST_ID} className={classes.userHudHolder}>
					<Paper
						square
						elevation={isInNav ? 4 : 0}
						key="show"
						style={isInNav ? undefined : { background: "transparent" }}
					>
						<UserHudRoot isInNav={isInNav} activeTab={activeTab} {...stats} />
					</Paper>
				</div>
			) : (
				<div key="hide" />
			)}
			<div>
				<BulkBiddingProgress />
			</div>
		</>
	);
};

const styles = {
	userHudHolder: {
		position: "relative"
		// zIndex: props => (props.isInNav ? -6 : undefined)
	}
};

export default useSheet(styles)(UserHud);
