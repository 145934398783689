import React from "react";
import useSheet from "react-jss";

const IconHolder = ({ classes, ...props }) => (
	<div className={classes.root} {...props} />
);

const styles = theme => {
	const u = theme.spacing.unit;
	return {
		root: {
			fontSize: u * 4,
			lineHeight: `${u * 3}px`,
			margin: [-u / 4, -u]
		}
	};
};

export default useSheet(styles)(IconHolder);
