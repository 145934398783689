import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { uniqueId } from "lodash";

import { WaitingIndicator as Indicator, Empty } from "@civicsource/ui";
import RejectedIcon from "../../rejected-icon";
import BidSummary from "./summary";
import BidDetails from "./entries";
import style from "./styles";

const BidHistoryHorizontal = ({ activeTab, onSelectTab, ...props }) =>
	!props.auction.bidCount && props.auction.rejectedBidCount ? (
		<BidDetails {...props} />
	) : (
		<Tabs
			id={uniqueId("bid-history-tabs-horizontal")}
			activeKey={activeTab}
			onSelect={onSelectTab}
		>
			<Tab eventKey="summary" title="Summary">
				<BidSummary {...props} />
			</Tab>
			<Tab
				eventKey="details"
				title={
					<span
						className={props.auction.rejectedBidCount ? "text-danger" : null}
					>
						{props.auction.rejectedBidCount ? <RejectedIcon /> : null} Detail
					</span>
				}
			>
				<BidDetails {...props} />
			</Tab>
		</Tabs>
	);

class BidHistoryStateContainer extends Component {
	state = {
		activeTab: "summary"
	};

	onSelectTab = key => {
		this.setState({ activeTab: key });
	};

	render() {
		const { ...props } = this.props;

		const {
			isBiddingInfoLoading,
			isBiddingInfoLoaded,
			isBiddingInfoMissing,
			biddingInfoLoadError,
			bids
		} = props.auction;

		if (isBiddingInfoMissing) {
			return null;
		}

		if (isBiddingInfoLoading && !isBiddingInfoLoaded) {
			return <Indicator showWaitingIndicator message="Loading bids..." />;
		}

		if (bids.isLoading && !bids.isLoaded) {
			return <Indicator showWaitingIndicator message="Loading bids..." />;
		}

		if (biddingInfoLoadError || bids.isError) {
			return <Indicator message="Error loading bids." />;
		}

		if (!bids.items.length) {
			return (
				<Empty>
					{props.auction.isEnded
						? "No bids were placed."
						: "No bids placed yet."}
				</Empty>
			);
		}

		return (
			<BidHistoryHorizontal
				{...props}
				{...this.state}
				onSelectTab={this.onSelectTab}
			/>
		);
	}
}

export default style(BidHistoryStateContainer);
