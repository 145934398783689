import { useCallback } from "react";

const useMapNavHover = mapView => {
	return useCallback(() => {
		if (mapView) {
			document
				.getElementsByClassName("navbar")[0]
				.setAttribute("style", "opacity: 1");
		}
	}, [mapView]);
};

export default useMapNavHover;
