import { useCallback } from "react";
import { useFetch } from "react-fetch-hooks";
import { useMessageHandler } from "../channel/context";

import { get } from "lodash";

import socketMessageToAction from "./socket-message-to-action";
import useUserHudReducer from "./reducer";
import useInitialAuctionsDispatch from "./initial-dispatch";
import { usePresenter } from "./presenter";

const useUserHud = user => {
	let { body: auctions, error } = useFetchHudAuctions(get(user, "token"));

	const [auctionState, dispatch] = useUserHudReducer();
	useInitialAuctionsDispatch({ auctions, dispatch });

	const handleMessage = useHandleMessage(dispatch);
	useMessageHandler(handleMessage);

	const presented = usePresenter(auctionState);

	return { error, ...presented };
};

const useFetchHudAuctions = bearerToken => {
	const url = `${process.env.BIDDING_API_URL}auctions/hud`;
	return useFetch(bearerToken ? { url, bearerToken } : null);
};

const useHandleMessage = dispatch =>
	useCallback(
		msg => {
			const actionified = socketMessageToAction(msg);
			dispatch(actionified);
		},
		[dispatch]
	);

export default useUserHud;
