import React, { Component } from "react";
import { InputBase } from "@material-ui/core";
import getAdornmentProps from "./adornment";
import {
	normalizePercentagesForDisplay,
	normalizePercentagesForCalculations
} from "../normalize-percentages";
import { bidInputPattern } from "../amount/format-bid";

export default class BidAmountInput extends Component {
	componentDidUpdate(prevProps) {
		var inputEl = this._bidAmountInput;
		// if input exists, doesn't have focus and just went from disabled to enabled
		if (
			inputEl &&
			inputEl !== document.activeElement &&
			!this.props.isDisabled &&
			prevProps.isDisabled
		) {
			setTimeout(() => inputEl.focus(), 0);
		}
	}

	onBidAmountChanged = ev => {
		const amount = ev.target.value;
		const { isMoney, onChange } = this.props;
		const value = normalizePercentagesForCalculations(amount, isMoney);

		if (onChange) onChange(value);
	};

	_bidAmountInput = null;

	setInputRef = e => (this._bidAmountInput = e);

	render() {
		const {
			auction,
			isMoney,
			amount: _amount,
			min: _min,
			max: _max
		} = this.props;

		const increment = normalizePercentagesForDisplay(
			auction.thresholds.bidIncrement || 1,
			isMoney
		);

		const min = normalizePercentagesForDisplay(_min, isMoney);
		const max = normalizePercentagesForDisplay(_max, isMoney);
		const amount = normalizePercentagesForDisplay(_amount, isMoney);

		const inputProps = {
			type: "number",
			min,
			max,
			step: increment,
			pattern: bidInputPattern(isMoney)
		};

		const htmlId = `${auction.lotNumber}-place-bid-input`;

		return (
			<InputBase
				style={{ display: "flex" }}
				{...getAdornmentProps(isMoney)}
				disabled={this.props.isDisabled}
				aria-label="Place bid amount"
				id={htmlId}
				value={amount}
				onChange={this.onBidAmountChanged}
				inputProps={inputProps}
				inputRef={this.setInputRef}
			/>
		);
	}
}
