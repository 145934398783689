import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "15px",
		height: "15px"
	}
});

export const KsIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="210mm"
		height="297mm"
		viewBox="0 0 210 297"
		version="1.1"
	>
		<g id="state_of_ks_15px" stroke="none" strokeWidth="10" fill="none">
			<path
				d="M 2.4882001,61.767968 2.0399069,202.20024 H 208.70319 v -70.0132 -35.006598 l -6.08463,-13.998876 -9.12099,-9.650315 3.24329,-5.324677 -2.60703,-3.226472 -4.65752,1.883175 -1.39462,-4.892372 z"
				id="path7041"
				stroke="#7133FF"
				strokeWidth="10"
			/>
		</g>
	</svg>
));
