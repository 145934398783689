import React from "react";
import customTooltip from "./tooltip.style";
import { PropTypes as t } from "prop-types";

const messages = {
	TooLow: (
		<span>
			Someone else placed a bid before yours.
			<br /> Try placing another bid.
		</span>
	),
	TooHigh: "Your bid was higher than the maximum amount.",
	TerminalBidReached: "The terminal bid has been reached.",
	LessThanUnit: "The bid must be a whole dollar or percent.",
	AuctionNotFound: "The auction could not be found.",
	AuctionCanceled: "The auction has been canceled.",
	AuctionNotStarted: "The auction has not started.",
	AuctionHasEnded: "The auction has ended.",
	Unauthorized: "Please sign in to place a bid.",
	Forbidden: "You do not have permission to place a bid.",
	ServerError: (
		<span>
			There was a problem placing your bid.
			<br />
			Please refresh the page and try again in a moment.
		</span>
	)
};

const Rejection = ({
	tooltipPlacement = "bottom",
	rejectionReason,
	...props
}) => {
	const styles = customTooltip(tooltipPlacement, "danger");
	const message = messages[rejectionReason] || messages["ServerError"];
	return (
		<div
			className={`tooltip in ${tooltipPlacement}`}
			style={{ ...styles.tooltipZ }}
			{...props}
		>
			<div className="tooltip-arrow" style={{ ...styles.arrow }} />
			<div
				className="tooltip-inner"
				style={{
					...styles.large,
					...styles.hasIcon,
					...styles.inner
				}}
			>
				<div className="clearfix text-left">
					<i
						className="fa fa-2x fa-exclamation-circle"
						style={{ ...styles.icon }}
					/>
					<div>
						<p>{message}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

Rejection.propTypes = {
	rejectionReason: t.string.isRequired,
	tooltipPlacement: t.oneOf(["top", "right", "bottom", "left"])
};

export default Rejection;
