import React, { Component } from "react";
import { get, invoke } from "lodash";
import PlaceBidForm from "./form";
import Validation from "./validation";
import Placeholders from "./placeholders";
import { Overlay } from "react-bootstrap";
import connect from "./connect";
import massagePlaceBid from "./massage";
import { compose } from "recompose";
import BidPlaceTooltip from "./tooltip";
import SignInButton from "./sign-in-button";
import moment from "moment";
import BidSave from "../save";

class PlaceBid extends Component {
	static defaultProps = {
		showPlaceholder: true,
		showSignInButton: false,
		tooltipPlacement: "bottom"
	};

	state = {
		amount: getAmount(this.props.auction)
	};

	_placeBidForm = null;

	submitBid = ev => {
		invoke(ev, "preventDefault");

		this.setState(
			({ amount }, { auction }) => ({
				amount: getAmount(auction, amount)
			}),
			() => {
				this.props.onSubmitBid(this.props.auction.lotNumber, this.state.amount);
			}
		);
	};

	onAmountChanged = amount => {
		// If money and they try to input a decimal number with precision great than 2 (ex 1500.135), dont change state
		if (
			getIsMoney(this.props.auction.bidType) &&
			isDecimal(toFixed(amount * 100)) // Greater than 2 decimal places
		) {
			return;
		}

		this.setState({ amount: Number(amount) });
	};

	render() {
		const {
			auction,
			isAuthenticated,
			showPlaceholder,
			showSignInButton
		} = this.props;

		if (!auction || !auction.isBiddingInfoLoaded || auction.isCanceled)
			return null;

		const {
			userNextBidAmount,
			bidType,
			thresholds,
			isLoading,
			isError,
			isCanceled,
			isOpen,
			isEnded
		} = auction;

		const { amount } = this.state;

		if (!isOpen) {
			if (
				isAuthenticated &&
				moment(get(auction, "schedule.startTime")).isAfter(new Date())
			) {
				if (!process.env.FEATURE_BULK_BIDDING_ENABLED) {
					return <strong>The auction has not started.</strong>;
				}

				return (
					<BidSave
						user={this.props.user}
						lotNumber={get(auction, "lotNumber")}
						userSavedBid={get(auction, "userSavedBid")}
						startingBid={get(auction, "thresholds.startingBid")}
						isMoney={getIsMoney(bidType)}
					/>
				);
			}
			if (isEnded || isError || isLoading || isCanceled) {
				return <Placeholders {...{ showPlaceholder }} {...auction} />;
			}
		}

		if (!isAuthenticated) {
			return showSignInButton ? <SignInButton /> : null;
		}

		const isMoney = getIsMoney(bidType);
		const min = isMoney ? userNextBidAmount : thresholds.terminalBid;
		const max = isMoney
			? thresholds.terminalBid || 1000000000
			: userNextBidAmount;

		const tooltipOverlay = (
			/* eslint-disable react/jsx-no-bind */
			<Overlay show container={this} target={() => this._placeBidForm}>
				<div>
					<BidPlaceTooltip {...this.props} />
				</div>
			</Overlay>
		);

		return (
			<div style={formStyle} ref={e => (this._placeBidForm = e)}>
				<PlaceBidForm
					{...this.props}
					{...{ amount, max, min }}
					onAmountChanged={this.onAmountChanged}
					onSubmitBid={this.submitBid}
				/>
				<Validation {...{ min, max, bidType, amount }} />

				{tooltipOverlay}
			</div>
		);
	}
}

const formStyle = { position: "relative" };

const getAmount = (
	auction,
	amount = get(auction, "userSavedBid") || get(auction, "userNextBidAmount", 0)
) => {
	// If it's a money decimal amount, format it to 2 decimal places before submitting
	const bidType = get(auction, "bidType");
	if (getIsMoney(bidType) && isDecimal(amount)) {
		amount = toFixed(amount);
	}
	return amount;
};

const BID_TYPES = { MONEY: "Money" };

const getIsMoney = bidType => bidType === BID_TYPES.MONEY;
const isDecimal = amount => (Number(amount) ? Number(amount) % 1 != 0 : false);
const toFixed = amount => (Number(amount) ? Number(amount).toFixed(2) : amount);

const ConnectedPlaceBid = compose(
	connect,
	massagePlaceBid
)(PlaceBid);

const WrapPlaceBid = props => (
	<ConnectedPlaceBid key={get(props, "auction.userNextBidAmount")} {...props} />
);

export default WrapPlaceBid;
