import React from "react";
import useSheet from "react-jss";
import { Paper } from "@material-ui/core";

const InnerWrapper = ({ children, classes }) => (
	<Paper className={classes.root}>{children}</Paper>
);

const styles = {
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "20em"
	}
};

export default useSheet(styles)(InnerWrapper);
