import { useEffect } from "react";

const useInitialAuctionsDispatch = ({ auctions, dispatch }) =>
	useEffect(() => {
		if (auctions && auctions.length) {
			dispatch({
				type: USER_BIDDING_AUCTIONS_FETCH,
				payload: auctions
			});
		}
	}, [auctions, dispatch]);

export const USER_BIDDING_AUCTIONS_FETCH = "USER_BIDDING_AUCTIONS_FETCH";

export default useInitialAuctionsDispatch;
