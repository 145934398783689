import { connect } from "fetch-helpers";
import { connectCurrentUser } from "@civicsource/users";

export default ComponentToWrap =>
	connectCurrentUser(
		connect(({ user }) =>
			user
				? {
						count: {
							url: `${process.env.ADMIN_API_URL}checkoutcount/`,
							bearerToken: user.token,
							keys: ["user"]
						}
				  }
				: null
		)(ComponentToWrap)
	);
