import React from "react";
import BidTodayWinToday from "../details/info/bid-today-win-today";

export default {
	GOING_ONCE: {
		subject: <span>Going Once</span>,
		body: <p>The auction will end in a moment, unless a bid is placed.</p>,
		htmlClass: "once"
	},
	GOING_TWICE: {
		subject: <span>Going Twice</span>,
		body: (
			<p>
				<span className="text-phrase">The auction is about to end.</span>{" "}
				<span className="text-phrase">Place your final bid now.</span>
			</p>
		),
		htmlClass: "twice"
	},
	EXTENDED: {
		subject: <span>Bidding Extended</span>,
		body: (
			<p>
				Bidding has been extended due to a bid placed in the last few minutes of
				the auction.
			</p>
		),
		htmlClass: "extended"
	},
	CANCELED: {
		subject: <span>Canceled</span>,
		body: <p />,
		htmlClass: "canceled"
	},
	WON: {
		subject: <span>Sold</span>,
		body: <p>Congratulations! You won the auction.</p>,
		htmlClass: "won"
	},
	LOST: {
		subject: <span>Sold</span>,
		body: <p>You did not win the auction.</p>,
		htmlClass: "lost"
	},
	SOLD: {
		subject: <span>Sold</span>,
		body: <p />,
		htmlClass: "sold"
	},
	NOT_SOLD: {
		subject: <span>Not Sold</span>,
		body: <p />,
		htmlClass: "notSold"
	},
	ADVANCED: {
		subject: <span>Ending Today</span>,
		body: (
			<p>
				This a <BidTodayWinToday titleClass="alert-link" /> auction.
				<br />
				Since a bid has been placed, the scheduled end time has been moved up.
			</p>
		),
		htmlClass: "advanced"
	}
};
