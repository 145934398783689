import React from "react";
import cx from "classnames";
import Bidder from "./bidder";
import RejectedIcon from "../../../../rejected-icon";
import { Time } from "@civicsource/ui";

import { Paddle as IconPaddle } from "../icons";

import formatBid from "../../../amount/format-bid";

const BidHistoryItem = ({ classes, auction, bid, getUserUrl }) => {
	const isCurrentBid =
		auction.userCurrentBid && auction.userCurrentBid.id == bid.id;

	const showProxies = !bid.isProxy;

	var bidClasses = cx({
		[classes.item]: true,
		[classes.private]: !bid.isPublic,
		[classes.canceled]: bid.isCanceled,
		[classes.proxy]: bid.isProxy && showProxies,
		[classes.proxyPublic]: bid.isPublic && bid.isProxy,
		[classes.you]: bid.isYourBid,
		[classes.youPublic]: bid.isYourBid && bid.isPublic,
		[`success ${classes.youWinning}`]: bid.isWinning && bid.isYourBid,
		[`danger ${classes.youLosing}`]:
			bid.isPublic && !bid.isWinning && isCurrentBid,
		success: bid.isWinning,
		"danger text-danger": bid.isRejected
	});

	const maxBidExplanation =
		"This is a bid placed by the user. Bids without this icon are proxy bids.";
	const proxyBidExplanation =
		"This is a proxy bid (a bid automatically placed on the user's behalf, as opposed to their maximum bid).";
	const helpText = bid.isProxy ? proxyBidExplanation : maxBidExplanation;

	var bidIcon = showProxies ? (
		<span>
			<IconPaddle />
		</span>
	) : null;
	var amountFormatted = formatBid(bid.amount, auction.bidType);

	const rejectedIcon = bid.isRejected ? <RejectedIcon /> : null;

	const canceledIcon = bid.isCanceled ? (
		<i className="fa fa-times" title="Canceled" />
	) : null;

	return (
		<tr className={bidClasses}>
			<td>
				{rejectedIcon}
				{canceledIcon} <Bidder bid={bid} getUserUrl={getUserUrl} />
			</td>
			<td className="text-right">
				<span title={helpText}>
					{bidIcon} {amountFormatted}
				</span>
			</td>
			<td className="text-center">
				<Time value={bid.time} format="MM/DD/YYYY hh:mm:ss.SSS A" />
			</td>
		</tr>
	);
};

export default BidHistoryItem;
