import React, { useState, useCallback } from "react";
import { Video } from "@civicsource/ui";
import { invoke } from "lodash";

const BidTodayWinToday = ({ title, titleClass }) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleOpenModal = useCallback(
		e => {
			invoke(e, "preventDefault");
			setIsOpen(true);
		},
		[setIsOpen]
	);
	const handleCloseModal = useCallback(() => setIsOpen(false), [setIsOpen]);

	const videoObj = {
		title: "Bid Today, Win Today",
		id: "g8K21P8CoeI"
	};

	return (
		<span>
			<a href="#" className={titleClass || ""} onClick={handleOpenModal}>
				{title || "Bid Today, Win Today"}
			</a>
			<Video.Player
				video={videoObj}
				show={isOpen}
				onHide={handleCloseModal}
				bsSize="large"
			/>
		</span>
	);
};

export default BidTodayWinToday;
