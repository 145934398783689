import React from "react";

const Bidder = props => {
	const bid = props.bid;

	if (bid.isYourBid) {
		return <span>You</span>;
	}

	const url = props.getUserUrl ? props.getUserUrl(bid.bidder) : null;

	const userDisplay = url ? (
		<a href={url}>
			<span>{bid.bidder}</span>
		</a>
	) : (
		<span>{bid.bidder}</span>
	);

	return userDisplay;
};

export default Bidder;
