import React from "react";
import { formatNumber, formatMoney } from "accounting";
import useSheet from "react-jss";
import { Grid } from "react-bootstrap";
import DashItem from "./dash-item";
import { ReactComponent as CheckCircle } from "../icons/check-circle.svg";
import { ReactComponent as Save } from "../icons/save.svg";
import { ReactComponent as Warning } from "../icons/warning.svg";
import { ReactComponent as Block } from "../icons/block.svg";
import {
	COLOR_WINNING,
	COLOR_LOSING,
	COLOR_SAVE,
	COLOR_CANCELED
} from "../colors";

import { Link } from "@reach/router";

import BulkBidder from "./bulk-bidder";
import { BIDDING_TABS } from "../user-bids";
import { bootstrap } from "toetag";

const UserHudRoot = ({
	isInNav,
	error,
	winningCount,
	losingCount,
	savedCount,
	canceledCount,
	winningSum,
	losingSum,
	savedSum,
	canceledSum,
	activeTab,
	classes
}) => {
	return (
		<div className={classes.container}>
			<Grid>
				<div className={classes.innerContainer}>
					{error ? (
						<div key="error" className={classes.errorContainer}>
							<Warning titleAccess={error.message} title={error.message} />
						</div>
					) : null}
					{winningCount || !isInNav ? (
						<Link to="/bids" className={classes.dashItemLink}>
							<DashItem
								isActive={activeTab === BIDDING_TABS.WINNING}
								key="winning"
								Icon={CheckCircle}
								iconColor={COLOR_WINNING}
								label="Winning"
								value={`${formatNumber(winningCount)} / ${formatMoney(
									winningSum
								)}`}
							/>
						</Link>
					) : null}
					{losingCount || !isInNav ? (
						<Link to="/bids/losing" className={classes.dashItemLink}>
							<DashItem
								isActive={activeTab === BIDDING_TABS.LOSING}
								key="losing"
								Icon={Warning}
								iconColor={COLOR_LOSING}
								label="Losing"
								value={`${formatNumber(losingCount)} / ${formatMoney(
									losingSum
								)}`}
							/>
						</Link>
					) : null}
					{(savedCount || !isInNav) &&
					process.env.FEATURE_BULK_BIDDING_ENABLED ? (
						<Link to="/bids/saved" className={classes.dashItemLink}>
							<DashItem
								isActive={activeTab === BIDDING_TABS.SAVED}
								key="saved"
								Icon={Save}
								iconColor={COLOR_SAVE}
								label="Saved"
								value={`${formatNumber(savedCount)} / ${formatMoney(savedSum)}`}
							/>
						</Link>
					) : null}
					{(canceledCount && !isInNav) ||
					(activeTab && activeTab === BIDDING_TABS.CANCELED) ? (
						<Link to="/bids/canceled" className={classes.dashItemLink}>
							<DashItem
								isActive={activeTab === BIDDING_TABS.CANCELED}
								key="canceled"
								Icon={Block}
								iconColor={COLOR_CANCELED}
								label="Canceled"
								value={`${formatNumber(canceledCount)} / ${formatMoney(
									canceledSum
								)}`}
							/>
						</Link>
					) : null}
					{process.env.FEATURE_BULK_BIDDING_ENABLED ? (
						<div className={classes.bulkBiddingContainer} key="bulkBidding">
							<BulkBidder hasSavedBids={!!savedCount} />
						</div>
					) : null}
				</div>
			</Grid>
		</div>
	);
};

const styles = {
	container: {},
	innerContainer: {
		minHeight: 64,
		display: "flex",
		justifyContent: "center",
		[`@media screen and (max-width: ${bootstrap.gridFloatBreakpointMax})`]: {
			flexDirection: "column"
		}
	},
	errorContainer: {
		padding: [13, 15]
	},
	bulkBiddingContainer: {
		padding: [8, 24],
		display: "flex",
		alignItems: "center"
	},
	dashItemLink: {
		"&, &:hover, &:focus": {
			textDecoration: "none"
		}
	}
};

export default useSheet(styles)(UserHudRoot);
