export function normalize(lotNumber) {
	return lotNumber ? lotNumber.toUpperCase().trim() : "";
}

export function normalizeNew(lotNumber) {
	return `${normalize(lotNumber)}_new`;
}

export function extractTaxAuthorityPrefix(lotNumber) {
	return lotNumber
		? lotNumber
				.trim()
				.substring(0, 3)
				.toUpperCase()
		: lotNumber;
}
