import React from "react";

import { connectCurrentUser } from "@civicsource/users";

import NavbarRoot from "./root";
import useNavbar from "./use-navbar";

const StatefulNav = props => {
	return <NavbarRoot {...props} {...useNavbar(props)} />;
};

export default connectCurrentUser(StatefulNav);
