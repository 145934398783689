import { useEffect } from "react";

const useNavbarInit = ({
	setNavStyleForMap,
	checkWindowCollapsed,
	navbarCollapsed,
	handleSubmit
}) => {
	useEffect(() => {
		window.addEventListener("hashchange", setNavStyleForMap);
		window.addEventListener("resize", checkWindowCollapsed);
		window.addEventListener("click", navbarCollapsed);
		window.addEventListener("submit", handleSubmit);
		checkWindowCollapsed();
		setNavStyleForMap();

		return () => {
			window.removeEventListener("hashchange", setNavStyleForMap);
			window.removeEventListener("resize", checkWindowCollapsed);
			window.removeEventListener("click", navbarCollapsed);
			window.removeEventListener("submit", handleSubmit);
		};
	}, [checkWindowCollapsed, handleSubmit, navbarCollapsed, setNavStyleForMap]);
};

export default useNavbarInit;
