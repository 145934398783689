import { bootstrap } from "toetag";
import tinycolor from "tinycolor2";
import useSheet from "react-jss";

const losingBorderColor = tinycolor.mix(
	bootstrap.btnDangerBorder,
	bootstrap.alertDangerBg
);

export default useSheet({
	item: {},
	private: {},
	proxy: {},
	proxyPublic: {},
	you: {},
	youPublic: {},
	youWinning: {
		borderLeft: `4px solid ${bootstrap.btnSuccessBorder}`
	},
	youLosing: {
		borderLeft: `4px solid ${losingBorderColor}`
	},
	canceled: {
		composes: ["warning", "text-warning"]
	},
	enterOrExit: {
		transition: ".5s all",
		transform: "translateX(-25%)",
		opacity: 0
	},
	enter: { extend: "enterOrExit" },
	exit: { extend: "enterOrExit" },
	active: {
		transform: "translateX(0%)",
		opacity: 1
	},
	muted: {
		opacity: 0.8
	},
	maxBid: {
		display: "block",
		composes: "$muted"
	},
	maxBidIcon: {
		composes: "$muted"
	}
});
