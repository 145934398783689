import "./src/layout/less/auctioneer.less";
import "./src/layout/less/views/home/main.less";
import Provider from "./src/state/provider";
import moment from "moment";

moment.updateLocale("en", {
	meridiem: function(hour, minute, isLowercase) {
		if (hour >= 12) {
			return isLowercase ? "p.m." : "P.M.";
		} else {
			return isLowercase ? "a.m." : "A.M.";
		}
	}
});

export const wrapRootElement = Provider;
