import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchBidHistory, fetchMoreBidHistory } from "./actions";

import connectAuction from "../../auction/connect";

export default function connectBidHistory(component) {
	const Connector = connect(
		state => state,
		dispatch =>
			bindActionCreators(
				{
					fetchBidHistory,
					fetchMoreBidHistory
				},
				dispatch
			)
	)(component);

	return connectAuction(Connector);
}
