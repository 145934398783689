import React from "react";
import moment from "moment";
import formattedTime from "./format-time";
import { HighlightOnChange } from "@civicsource/ui";
import Going from "../going";
import BidTodayWinToday from "../details/info/bid-today-win-today";

import ExtensionHelp from "./extension-help";

const BASE = "auction-time__description";

const AuctionTime = ({
	auction,
	customPlaceholder = null,
	showHelp = false,
	showGoing = true,
	isSearchResult
}) => {
	if (!auction.isBiddingInfoLoaded) {
		return customPlaceholder;
	}

	if (!auction.schedule) {
		return <span>Not scheduled</span>;
	}

	const {
		schedule: { startTime, endTime, allowEndAdvancement, advancedEndTime },
		isEnded,
		isOpen
	} = auction;

	const start = moment(startTime);
	const end = moment(endTime);

	const currentTime = moment();

	const isStarted = currentTime.isAfter(start);

	const extendWithHelp = showHelp ? (
		<span>
			<br />
			<ExtensionHelp endTime={endTime} />
		</span>
	) : null;

	const helpIcon = <i className="fa fa-question-circle text-primary" />;
	const advancedEndText =
		allowEndAdvancement && !advancedEndTime ? (
			<>
				<br />
				<span>
					or day of first bid{" "}
					<BidTodayWinToday
						title={isSearchResult ? helpIcon : "What is this?"}
						titleClass={isSearchResult ? "" : "help-link"}
					/>
				</span>
			</>
		) : null;

	const extendedOrActive = (
		<span>
			Ends {formattedTime(end)} {advancedEndText}
			{extendWithHelp}
		</span>
	);

	let result = null;
	let goingComponent = null;

	if ((isEnded || isOpen) && !!showGoing) {
		goingComponent = (
			<div>
				<Going auction={auction} />
			</div>
		);
	}

	if (!isStarted) {
		result = (
			<span className={`${BASE} ${BASE}--not-started`}>
				Starts {formattedTime(start)}
			</span>
		);
	}

	if (isEnded) {
		result = (
			<span className={`${BASE} ${BASE}--ended`}>
				Ended {formattedTime(end)} {goingComponent}
			</span>
		);
	}

	if (isOpen) {
		result = (
			<span className={`${BASE} ${BASE}--active`}>
				{extendedOrActive} {goingComponent}
			</span>
		);
	}

	return (
		<div>
			<HighlightOnChange subscribeTo={end.toString()} highlighDuration={5000}>
				{result}
			</HighlightOnChange>
		</div>
	);
};

export default AuctionTime;
