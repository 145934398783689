import React from "react";
import { Button } from "@material-ui/core";
import useSheet from "react-jss";
import tc from "tinycolor2";

const ColorizedOutlineButton = ({ classes, color, ...props }) => (
	<Button
		variant="outlined"
		data-color={color}
		{...props}
		className={`${classes.root} ${props.className || ""}`}
	/>
);

const styles = {
	root: {
		"&, &:hover, &:focus, &:active": {
			color: props => props.color,
			borderColor: props => props.color
		},
		"&:hover": {
			backgroundColor: props =>
				tc(props.color)
					.setAlpha(0.08)
					.toString()
		},
		"& svg path": {
			fill: "currentColor"
		}
	}
};

export default useSheet(styles)(ColorizedOutlineButton);
