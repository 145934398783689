export default {
	itemsWrapper: {
		flex: "1 0 auto"
	},
	items: { marginBottom: [[0], "!important"] },
	item: {
		background: "#fff",
		"&:first-child": { border: "none" },
		"&:last-child": { borderBottom: "none" }
	},
	canceled: {
		textDecoration: "line-through"
	}
};
