import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { createStore } from "./index";

import { Provider as ChannelProvider } from "../channel/context";

// eslint-disable-next-line react/display-name
export default ({ element }) => (
	<ReduxProvider store={createStore()}>
		<ChannelProvider>{element}</ChannelProvider>
	</ReduxProvider>
);
