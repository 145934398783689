import { get } from "lodash";

import useInitializeSavedBid from "./initialize";
import useSaveSavedBid from "./save";
import useDeleteSavedBid from "./delete";
import useSavedBidInputEvents from "./input-events";

const useSavedBid = ({
	lotNumber,
	userSavedBid,
	startingBid,
	user,
	isMoney
}) => {
	// build request URL & auth
	const req = {
		url: getSavedBidsUrlByLotNumber(lotNumber),
		bearerToken: get(user, "token")
	};

	// initialize
	const { amount, setAmount } = useInitializeSavedBid({
		userSavedBid,
		startingBid,
		isMoney
	});

	return {
		amount,
		setAmount,
		...useSavedBidInputEvents({ amount, setAmount, isMoney }),
		...useSaveSavedBid({
			amount,
			isMoney,
			setAmount,
			...req
		}),
		...useDeleteSavedBid({
			isMoney,
			startingBid,
			setAmount,
			...req
		})
	};
};

const biddingUrl = process.env.BIDDING_API_URL;
const savedBidsUrl = `${biddingUrl}auctions/`;
const getSavedBidsUrlByLotNumber = lotNumber => `${savedBidsUrl}${lotNumber}/`;

export default useSavedBid;
