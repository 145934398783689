import { useState } from "react";

const useNavbarState = () => {
	const [openMenu, setOpenMenu] = useState(null);
	const [mouseInside, setMouseInside] = useState(null);
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [windowCollapsed, setWindowCollapsed] = useState(null);

	return {
		openMenu,
		setOpenMenu,
		mouseInside,
		setMouseInside,
		navbarOpen,
		setNavbarOpen,
		windowCollapsed,
		setWindowCollapsed
	};
};

export default useNavbarState;
