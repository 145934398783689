import React from "react";
import { CircularProgress } from "@material-ui/core";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";
import IconHolder from "./icon-holder";
import { ErrorOutline } from "@material-ui/icons";

const SaveBidButton = ({ isSaving, isSaved, saveError }) => {
	const saveButtonTitle = isSaved ? "Saved!" : isSaving ? "Saving" : "Save bid";

	let errorTitle = null;

	if (saveError) {
		const { response: errorResp } = saveError;

		errorTitle = `Error saving:\n${
			errorResp ? errorResp.body.message : "Server connection failed."
		}`;
	}

	return (
		<div title={saveButtonTitle}>
			<IconHolder>
				{saveError ? (
					<ErrorOutline titleAccess={errorTitle} />
				) : isSaving ? (
					<CircularProgress size={22} title={saveButtonTitle} />
				) : (
					<SaveIcon title={saveButtonTitle} />
				)}
			</IconHolder>
		</div>
	);
};

export default SaveBidButton;
