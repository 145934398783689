import React, { useState, useCallback, createContext, useContext } from "react";

import { LinearProgress } from "@material-ui/core";
import { lightBlue, red } from "@material-ui/core/colors";
import useSheet from "react-jss";

const BulkBiddingProgress = ({ classes }) => {
	const { isSessionInProgress, isSessionComplete } =
		useBulkBiddingInProgress() || {};

	if (!isSessionInProgress && !isSessionComplete) {
		return null;
	}

	return <LinearProgress variant="indeterminate" classes={classes} />;
};

const styles = {
	root: {
		height: 4,
		marginTop: -4,
		position: "relative",
		overflow: "hidden"
	},
	// value
	bar1Buffer: {
		backgroundColor: lightBlue[500]
	},
	// valueBuffer
	bar2Buffer: {
		backgroundColor: red[500]
	}
};

const BulkBidderInProgressContext = createContext();

export const useBulkBiddingInProgress = () => {
	return useContext(BulkBidderInProgressContext) || {};
};

export const BulkBidderInProgressProvider = ({ children }) => {
	const [isSessionInProgress, setIsSessionInProgress] = useState(false);
	const [isSessionComplete, setIsSessionComplete] = useState(false);

	const resetSession = useCallback(() => {
		setIsSessionComplete(false);
		setIsSessionInProgress(false);
	}, []);

	return (
		<BulkBidderInProgressContext.Provider
			value={{
				isSessionInProgress,
				isSessionComplete,
				setIsSessionInProgress,
				setIsSessionComplete,
				resetSession
			}}
		>
			{children}
		</BulkBidderInProgressContext.Provider>
	);
};

export default useSheet(styles)(BulkBiddingProgress);
