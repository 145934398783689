import React from "react";
import useSheet from "react-jss";
import { Typography } from "@material-ui/core";

const DashItem = ({
	Icon = "div",
	iconColor = "#000",
	label,
	value,
	classes
}) => (
	<div className={classes.root}>
		<div className={classes.iconContainer}>
			<Icon className={classes.icon} style={{ color: iconColor }} />
		</div>
		<div>
			<Typography
				variant="caption"
				color="textSecondary"
				className={classes.label}
			>
				{label}
			</Typography>
			<Typography
				data-testid={`${label.toLowerCase()}-dash-item`}
				variant="h6"
				className={classes.value}
			>
				{value}
			</Typography>
		</div>
	</div>
);

const styles = {
	root: {
		display: "flex",
		alignItems: "center",
		padding: [8, 24],
		background: props => (props.isActive ? "#fff" : "rgba(255,255,255,0.375)"),
		borderTop: "2px solid",
		borderTopColor: props => (props.isActive ? props.iconColor : "transparent")
	},
	iconContainer: {
		display: "flex",
		paddingRight: 8,
		fontSize: 48
	},
	icon: {
		"& path": {
			fill: "currentColor"
		}
	},
	label: {
		textTransform: "uppercase"
	},
	value: {
		lineHeight: 1.2
	}
};

export default useSheet(styles)(DashItem);
