import React, { useEffect } from "react";
import useSheet from "react-jss";
import enforceLogin from "../login";
import { compose } from "recompose";
import { CircularProgress } from "@material-ui/core";
import styles from "./index.style";
import SearchPrompt from "./search-prompt";
import InnerWrapper from "./inner-wrapper";
import { useUserHudVisibility } from "../user-hud/visibility";
import UserBidsRoot from "./root";
import { Router } from "@reach/router";

export const BIDDING_TABS = {
	WINNING: "WINNING",
	LOSING: "LOSING",
	SAVED: "SAVED",
	CANCELED: "CANCELED"
};

const UserBidsChrome = ({ children }) => (
	<>
		{children}
		<SearchPrompt />
	</>
);

const UserBids = ({ fetchUserAuctions, totalCount, isLoading, isLoaded }) => {
	const { setIsUserHudVisible } = useUserHudVisibility();
	useEffect(() => {
		setIsUserHudVisible(false);
		return () => setIsUserHudVisible(true);
	}, [setIsUserHudVisible]);

	useEffect(() => {
		document
			.querySelector(".layout-content-wrapper")
			.classList.add("layout-content-wrapper-bids-page");
		return () => {
			document
				.querySelector(".layout-content-wrapper")
				.classList.remove("layout-content-wrapper-bids-page");
		};
	});

	if (isLoading && !isLoaded) {
		return (
			<UserBidsChrome>
				<InnerWrapper>
					<CircularProgress title="Loading user's bids…" />
				</InnerWrapper>
			</UserBidsChrome>
		);
	}

	return (
		<UserBidsChrome>
			<Router>
				<UserBidsRoot
					path="bids"
					totalCount={totalCount}
					fetchUserAuctions={fetchUserAuctions}
				/>
				<UserBidsRoot
					path="bids/:type"
					totalCount={totalCount}
					fetchUserAuctions={fetchUserAuctions}
				/>
			</Router>
		</UserBidsChrome>
	);
};

const activeAuctionsWrapper = compose(
	useSheet(styles),
	enforceLogin
);

export default activeAuctionsWrapper(UserBids);
