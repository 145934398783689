import { useCallback } from "react";

const useHideMenu = ({
	hideMobileMenu,
	mouseInside,
	openMenu,
	windowCollapsed,
	setOpenMenu
}) =>
	useCallback(() => {
		if (!openMenu && !windowCollapsed) {
			return;
		}
		if (
			(openMenu && !openMenu.search) ||
			(openMenu && openMenu.search && !mouseInside)
		) {
			setOpenMenu(null);
		}
		if (!mouseInside) {
			hideMobileMenu();
		}
	}, [hideMobileMenu, mouseInside, openMenu, setOpenMenu, windowCollapsed]);

export default useHideMenu;
