import React from "react";
import { Link } from "@reach/router";

const SearchPrompt = () => (
	<>
		<br />
		<br />
		<div className="text-center">
			<Link to="/auctions" className="btn btn-primary btn-sm">
				Search for more properties
			</Link>
		</div>
		<br />
		<br />
	</>
);

export default SearchPrompt;
