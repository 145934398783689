import React from "react";
import cx from "classnames";
import msg from "./message-config";
import style from "./index.style";

const GoingMain = ({ classes, ...props }) => {
	const { auction, jumbo } = props;

	if (!auction || !auction.isBiddingInfoLoaded) {
		return null;
	}

	const {
		isEnded,
		isGoingOnce,
		isGoingTwice,
		schedule: { endTime, advancedEndTime, originalEndTime },
		userCurrentBid,
		winningBid
	} = auction;

	const isEndingSoon = isGoingOnce || isGoingTwice;
	const isExtended = advancedEndTime
		? endTime !== originalEndTime && endTime !== advancedEndTime
		: endTime !== originalEndTime;

	const isWinning = winningBid && winningBid.isYourBid;

	const isLosing =
		!isEnded && (!winningBid || (winningBid && !winningBid.isYourBid));

	const goingHtmlClasses = cx({
		[classes.base]: true,
		[classes.soon]: isEndingSoon,
		[classes.ended]: isEnded,
		[classes.jumbo]: props.jumbo,
		[classes.winning]: isWinning,
		[classes.losing]: isLosing && userCurrentBid
	});

	const goingStatus = evalGoingStatus({ ...auction, isExtended });

	const goingShort = msg[goingStatus] ? msg[goingStatus].subject : null;
	const goingShortFormatted = props.jumbo ? (
		<h3 className={classes.heading}>{goingShort}</h3>
	) : (
		goingShort
	);

	const goingDescription =
		msg[goingStatus] && jumbo ? msg[goingStatus].body : null;

	return goingStatus ? (
		<div
			className={`${goingHtmlClasses} ${
				msg[goingStatus] ? classes[msg[goingStatus].htmlClass] : ""
			}`}
		>
			{goingShortFormatted} {goingDescription}
		</div>
	) : null;
};

const evalGoingStatus = ({
	isCanceled,
	isEnded,
	winningBid,
	userCurrentBid,
	isGoingOnce,
	isGoingTwice,
	schedule: { advancedEndTime },
	isExtended
}) => {
	if (isCanceled) {
		return "CANCELED";
	}

	if (isEnded) {
		if (!winningBid) {
			return "NOT_SOLD";
		}

		if (winningBid.isYourBid) {
			return "WON";
		}

		if (userCurrentBid) {
			return "LOST";
		}

		return "SOLD";
	}

	if (isGoingOnce) {
		return "GOING_ONCE";
	}

	if (isGoingTwice) {
		return "GOING_TWICE";
	}

	if (isExtended) {
		return "EXTENDED";
	}

	if (advancedEndTime) {
		return "ADVANCED";
	}

	return null;
};

export default style(GoingMain);
