import { snakeCase, toUpper } from "lodash";

const socketMessageToAction = ({ type, body }) => {
	return {
		type: toUpper(snakeCase(type)),
		payload: body || {}
		// bad, un-debuggable things will happen if you don't pass an object-y payload
	};
};

export default socketMessageToAction;
