import * as acct from "accounting";

const FormatBid = function(amount, type) {
	const isMoney = type.toLowerCase() === "money";
	const isPercentage = type.toLowerCase() === "percentage";
	const amt = Number(amount);

	if (isMoney) {
		return acct.formatMoney(amt);
	}

	if (isPercentage) {
		return `${(amt * 100.0).toFixed(0)}%`;
	}

	return amt;
};

export const bidInputPattern = isMoney => (isMoney ? "\\d*.?\\d{0,2}" : "\\d*");

export default FormatBid;
