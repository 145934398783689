import React from "react";
import { LaIcon } from "./la-icon";
import { RiIcon } from "./ri-icon";
import { MsIcon } from "./ms-icon";
import { MoIcon } from "./mo-icon";
import { TnIcon } from "./tn-icon";
import { KsIcon } from "./ks-icon";

function StateIcon({ stateName }) {
	switch (stateName) {
		case "Louisiana":
			return <LaIcon style={{ marginTop: "4px" }} />;
		case "Rhode Island":
			return <RiIcon style={{ marginTop: "4px" }} />;
		case "Missouri":
			return <MoIcon style={{ marginTop: "4px" }} />;
		case "Mississippi":
			return <MsIcon style={{ marginTop: "4px" }} />;
		case "Tennessee":
			return <TnIcon style={{ marginTop: "4px" }} />;
		case "Kansas":
			return <KsIcon style={{ marginTop: "4px" }} />;
		default:
			break;
	}
}

export default StateIcon;
