import { createReducer } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize, normalizeNew } from "../../normalize";

export default createReducer(Immutable({}), {
	onBidSubmit(state, { lotNumber }) {
		return state.set(normalize(lotNumber), normalizeNew(lotNumber));
	},

	onBidSubmitCompleted(state, { id }, { lotNumber }) {
		return state.set(normalize(lotNumber), id);
	}
});

export function getCurrentBidId(state, lotNumber) {
	return state[normalize(lotNumber)];
}
