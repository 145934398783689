import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { authenticate, getAuthStatus } from "@civicsource/users";

export default WrappedComponent =>
	connect(
		getAuthStatus,
		dispatch => bindActionCreators({ authenticate }, dispatch)
	)(WrappedComponent);
