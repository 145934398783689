import { lightBlue } from "@material-ui/core/colors";

// Original colors
export const COLOR_BRAND_BLUE = "#2992d8";
export const COLOR_BRAND_GREEN = "#83bd39";
export const COLOR_BRAND_RED = "#d05c5f";
export const COLOR_BRAND_YELLOW = "#dbc22e";

export const COLOR_CERTIFICATES = COLOR_BRAND_BLUE;
export const COLOR_ADJUDICATION = COLOR_BRAND_RED;
export const COLOR_LIENS = COLOR_BRAND_GREEN;

// Feb 2021 style refresh
export const COLOR_AUCTION_INVESTMENT = "#08B681";
export const COLOR_AUCTION_PROPERTY = "#0F70FF";
export const COLOR_AUCTION_COMBINED = "#CC3C55";

export const BODY_TEXT = "#A4A1A1";
export const HOVER_BLUE = "#ADD6FF";
export const SUPPLEMENTARY_PURPLE = "#A400FF";

export const COLOR_UI_UPDATE_HIGHLIGHT = "#fffd66";

// icon colors from bulk bidding designs
// https://projects.invisionapp.com/share/MTRJ6FB8P63#/screens/357747418
export const COLOR_WINNING = "#33b749";
export const COLOR_BIDDER = lightBlue[300];
export const COLOR_LOSING = "#fb4d3d";
export const COLOR_SAVE = "#791e94";
export const COLOR_CANCELED = "#777";
export const COLOR_EDIT = "#eac435";
export const COLOR_TRASH = "#fb4d3d";
export const COLOR_MAP = "#255f91";
export const COLOR_EXCEL = "#33b749";
