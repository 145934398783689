import React from "react";
import useChannelDispatcher from "./dispatcher";
import DisconnectWarning from "./disconnect-warning";

const Channel = () => {
	useChannelDispatcher();
	return <DisconnectWarning />;
};

export default Channel;
