export const normalizePercentagesForDisplay = (value, isMoney) => {
	if (typeof isMoney === "undefined")
		throw new TypeError(
			"`isMoney` should be explicitly defined when normalizing bid amount for display"
		);
	return isMoney ? Number(value) : Number((value * 100.0).toFixed(0));
};

// Money can now be a decimal, so leave it alone, but make percentage an int
export const normalizePercentagesForCalculations = (value, isMoney) => {
	if (typeof isMoney === "undefined")
		throw new TypeError(
			"`isMoney` should be explicitly defined when normalizing bid amount for calculation"
		);
	return isMoney
		? Number(value)
		: Number((parseInt(value, 10) / 100.0).toFixed(2));
};

export const formatDisplayValue = (value, isMoney) => {
	if (typeof isMoney === "undefined")
		throw new TypeError(
			"`isMoney` should be explicitly defined when formatting display value"
		);

	return parseFloat(value).toFixed(isMoney ? 2 : 0);
};
