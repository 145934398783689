import React, { useEffect } from "react";
import { formatNumber } from "accounting";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grow,
	Typography
} from "@material-ui/core";
import ColorizedOutlineButton from "../../colorized-outline-button";
import { COLOR_BIDDER } from "../../colors";

import { get, isString } from "lodash";

import { useFetch } from "react-fetch-hooks";
import { useBulkBiddingInProgress } from "./progress";

import { useSelector } from "react-redux";
import { getCurrentUser } from "@civicsource/users";

const GrowDown = props => (
	<Grow style={{ transformOrigin: "50% 0 0" }} {...props} />
);

const useFetchBulkResults = () => {
	const user = useSelector(getCurrentUser);
	const {
		isSessionInProgress: sessionUrl,
		resetSession,
		isSessionComplete,
		setIsSessionComplete
	} = useBulkBiddingInProgress() || {};

	const bearerToken = get(user, "token");
	const { body: results } = useFetch(
		bearerToken && sessionUrl && isString(sessionUrl) ? sessionUrl : null,
		{
			bearerToken,
			refreshInterval: 5000
		}
	);

	const totalCount = get(results, "totalCount", 0);
	const successCount = get(results, "successCount", 0);
	const failureCount = get(results, "failureCount", 0);

	useEffect(() => {
		if (totalCount > 0 && totalCount == successCount + failureCount) {
			setIsSessionComplete(true);
		}
	}, [failureCount, setIsSessionComplete, successCount, totalCount]);

	return { isSessionComplete, resetSession, ...results };
};

const BulkBidResults = () => {
	const {
		isSessionComplete,
		resetSession,
		successCount,
		failureCount
	} = useFetchBulkResults();

	return (
		<Dialog
			disableBackdropClick
			onClose={resetSession}
			open={isSessionComplete}
			maxWidth="lg"
			scroll="body"
			TransitionComponent={GrowDown}
			aria-labelledby="bulk-bid-results-title"
		>
			<DialogTitle id="bulk-bid-results-title">Saved Bid Results</DialogTitle>
			<DialogContent
				style={{
					minHeight: "50vh",
					textAlign: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center"
				}}
			>
				<Typography gutterBottom variant="h2">
					{formatNumber(successCount + failureCount)} saved bids have been
					placed.
				</Typography>
			</DialogContent>
			<DialogActions>
				<ColorizedOutlineButton
					color={COLOR_BIDDER}
					size="large"
					fullWidth
					onClick={resetSession}
				>
					OK
				</ColorizedOutlineButton>
			</DialogActions>
		</Dialog>
	);
};

export default BulkBidResults;
