import React from "react";
import { Nav, NavItem } from "react-bootstrap";
import TopNavDropdown from "./dropdown";
import CheckoutCount from "../../checkout/badge";
import { withHandlers } from "recompose";
import { SearchIcon, AccountIcon } from "./icons";

const DesktopMenu = ({
	leftLinks,
	rightLinks,
	classes,
	handleOpenRightMenu,
	handleSetOpenMenu,
	shoppingCart,
	selected,
	user
}) => {
	return (
		<div className="row">
			<div className="col-md-12 col-sm-12">
				<Nav style={{ float: "right" }}>
					{leftLinks.map((item, idx) => (
						<TopNavDropdown
							key={item.text}
							{...item}
							{...{ idx, classes }}
							handleSetOpenMenu={handleSetOpenMenu}
							selected={selected === item.text}
						/>
					))}
					{rightLinks.map((item, idx) =>
						!item.search &&
						!user &&
						!item.account &&
						!item.loggedIn ? null : item.loggedIn ? (
							<TopNavDropdown
								key={item.text}
								{...{ idx, classes }}
								{...item}
								handleSetOpenMenu={handleSetOpenMenu}
								selected={selected === item.text}
							/>
						) : (
							<NavItem
								href={item.href}
								key={item.text}
								onClick={handleOpenRightMenu(item.text)}
								className={classes.navRightLinks}
								style={{
									background: selected === item.text ? "#434343" : null,
									height: "51px"
								}}
							>
								{item.account ? (
									<AccountIcon />
								) : item.search ? (
									<span>
										<SearchIcon />
									</span>
								) : (
									<span className="text-phrase">
										<span className="fa-stack">
											<i className={shoppingCart} />
											<CheckoutCount />
										</span>
									</span>
								)}
							</NavItem>
						)
					)}
				</Nav>
			</div>
		</div>
	);
};

export default withHandlers({
	handleOpenRightMenu: ({ handleSetOpenMenu, menuLinks }) => text => () => {
		handleSetOpenMenu(menuLinks.find(tab => tab.text === text));
	}
})(DesktopMenu);
